import React from 'react';
import CountdownEl from 'react-countdown';
import styled from 'styled-components';
import { FastForward } from 'styled-icons/boxicons-regular';

const CountDownContainer = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: ${props => props.theme.secondary};
`;

const NextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 24px;
  color: white;
  text-align: center;
  cursor: pointer;
  & span {
    display: block;
    font-size: 14px;
    margin-top: -8px;
  }
  & svg {
    margin-left: 3px;
  }
`;

const CountDown = props => {
  const { clicked, start, time, gotonext, rend, timeComplete } = props;

  let nextDiv = null;

  if (timeComplete) {
    nextDiv = (
      <NextContainer onClick={gotonext} disabled={true}></NextContainer>
    );
  }
  if (start) {
    nextDiv = (
      <NextContainer>
        <CountdownEl date={Date.now() + time} renderer={rend} />
      </NextContainer>
    );
  } else {
    nextDiv = (
      <NextContainer onClick={gotonext} disabled={true}>
        <FastForward size={50} />
      </NextContainer>
    );
  }

  return (
    <React.Fragment>
      <CountDownContainer>{nextDiv}</CountDownContainer>
    </React.Fragment>
  );
};

export default CountDown;
