import React, { Suspense, useEffect, useState } from "react";
import { Loader } from "../../components/Ui";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { SideBar } from "../../components/Ui";
import SessionExpired from "../../components/SessionExpired";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import styled from "styled-components";
import { useSelector } from "react-redux";

const MainContent = styled.div`
  position: relative;
  flex: 1 0 auto;
  padding-bottom: 50px;
  background-color: ${(props) => (props.isGame ? props.bggame : "inherit")};
`;

const BaseLayout = (props) => {
  const {
    cmp: C,
    logo,
    toggleSidebar,
    showSidebar,
    isLogged,
    logout,
    bootApp,
    langs,
    oauths,
    ownLogin,
    multipromo,
    oauthInfo,
    isExpired,
    userInfo,
    oauth_profile_url,
    footercontent,
    isGame,
  } = props;

  const [gameDate, setGameDate] = useState();
  const gameObj = useSelector((store) => store.promo.game[0]);
  useEffect(() => {
    if (gameObj && gameObj.interaction) {
      setGameDate(JSON.parse(gameObj.interaction.data));
    }
  }, [gameObj]);

  let lazy = null;

  const Content = (
    <>
      <Header
        isLogged={isLogged}
        logo={logo}
        toggleSidebar={toggleSidebar}
        showSidebar={showSidebar}
        logout={logout}
        bootApp={bootApp}
        langs={langs}
        oauths={oauths}
        ownLogin={ownLogin}
        multipromo={multipromo}
        oauthInfo={oauthInfo}
        userInfo={userInfo}
        oauth_profile_url={oauth_profile_url}
      />
      <MainContent
        bggame={gameDate && gameDate.settings.aspect.bggamecolor}
        isGame={isGame}
      >
        <SideBar
          isLogged={isLogged}
          show={showSidebar}
          logout={logout}
          toggleSidebar={toggleSidebar}
          oauths={oauths}
          ownLogin={ownLogin}
          oauthInfo={oauthInfo}
          bootApp={bootApp}
          langs={langs}
          oauth_profile_url={oauth_profile_url}
        />
        <main>
          <C isLogged={isLogged} />
        </main>
      </MainContent>
      <Footer footercontent={footercontent} />
    </>
  );

  let Comp = lazy ? (
    <Suspense fallback={<Loader show={true} />}>{Content}</Suspense>
  ) : (
    <React.Fragment>{Content}</React.Fragment>
  );

  return (
    <React.Fragment>
      {Comp}
      <SessionExpired isExpired={isExpired} />
    </React.Fragment>
  );
};

BaseLayout.defaultProps = {
  type: "base",
};

const mapStateToProps = (state) => {
  return {
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    langs: state.app.config.langs,
    showSidebar: state.ui.showSidebar,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
    oauthInfo: state.app.oauthInfo,
    isExpired: state.error.sessionExpired,
    error404: state.promo.error404,
    codes: state.user.user.codes,
    userInfo: state.user.user.userinfo,
    oauth_profile_url: state.user.user.oauth_profile_url,
    footercontent: state.cms.footer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    bootApp: () => dispatch(actionCreators.bootApp()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
    logout: () => dispatch(actionCreators.logout()),
    getUserInfo: () => dispatch(actionCreators.getUserInfo()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BaseLayout);
