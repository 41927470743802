import React from "react";
import PropTypes from "prop-types";
import { Button } from "../../Ui";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import logoStardustConnect from "../../../assets/images/logostardustconnect.png";

const Link = styled.a`
	color: white;
`;

const StardustButton = styled(Button)`
	background-color: #12ce6c;
	box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px,
		rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
	padding: 0px;
	border-radius: 2px;
	border: 1px solid transparent;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	width: 203px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	& img {
		width: 70px;
		margin-left: 4px;
	}
	&:hover {
		background-color: #12ce6c;
	}
`;

const Stardust = (props) => {
	const { isSingle, label, info, small, isfullwidth } = props;
	const [t] = useTranslation();
	return (
		<Link href={info ? info.loginurl : null}>
			<StardustButton small={small ? small : false} fullwidth={isfullwidth}>
				{isSingle ? label : t("social.signinstardust")}
				<img src={logoStardustConnect} />
			</StardustButton>
		</Link>
	);
};

Stardust.propTypes = {
	isSingle: PropTypes.bool,
	label: PropTypes.string,
	isfullwidth: PropTypes.bool,
};

Stardust.defaultProps = {
	isfullwidth: false,
};

export default Stardust;
