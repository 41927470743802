import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '..';
import styled from 'styled-components';

const TextContainer = styled.div`
    margin-bottom: 30px;
`;

const Text = (props) => {

    const { children, stile } = props;

    let st = {};
    if(stile) {
        st = JSON.parse(stile);
    }

    return (
        <TextContainer style={st}>
            <Typography as="p" type="text">
                {children}
            </Typography>
        </TextContainer>
    )
}

Text.propTypes = {
    children: PropTypes.any,
    stile: PropTypes.string
}

export default Text;
