import React from "react";
import styled from "styled-components";
import { Close as CloseIcon } from "styled-icons/evil";

const CloseContainer = styled.div``;

const Close = (props) => {
  const { clicked } = props;

  return (
    <CloseContainer onClick={clicked}>
      {/* <CloseIcon /> */}
      <img src={process.env.PUBLIC_URL + "/icons/menu-close.svg"} alt="" />
    </CloseContainer>
  );
};

export default Close;
