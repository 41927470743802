import * as actionTypes from "../actions/actionTypes";

const initialState = {
  intro: [],
  loadingintro: true,
  errorintro: false,
  footer: [],
  loadingfooter: true,
  errorfooter: false,
  how: [],
  coupon: [],
};

const cmsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CMS_INTRO_START:
      return {
        ...state,
        loadingintro: true,
      };
    case actionTypes.GET_CMS_INTRO_SUCCESS:
      return {
        ...state,
        loadingintro: false,
        intro: action.data,
      };
    case actionTypes.GET_CMS_INTRO_ERROR:
      return {
        ...state,
        loadingintro: false,
        errorintro: action.error,
      };
    case actionTypes.GET_CMS_FOOTER_START:
      return {
        ...state,
        loadingfooter: true,
      };
    case actionTypes.GET_CMS_FOOTER_SUCCESS:
      return {
        ...state,
        loadingfooter: false,
        footer: action.content,
      };
    case actionTypes.GET_CMS_FOOTER_ERROR:
      return {
        ...state,
        loadingfooter: false,
        errorfooter: action.error,
      };
    case actionTypes.GET_CMS_HOW_SUCCESS:
      return {
        ...state,
        how: action.content,
      };
    case actionTypes.GET_CMS_COUPON_SUCCESS:
      return {
        ...state,
        coupon: action.content,
      };
    default:
      return state;
  }
};

export default cmsReducer;
