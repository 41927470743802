import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { respondTo } from "../../../theme/mixin";

import { useTransition, animated } from "react-spring";

const StyledLoader = styled.svg`
	animation: rotate 2s linear infinite;
	margin: 0 auto;
	width: ${(props) => (props.inBtn ? "20px" : "50px")};
	height: ${(props) => (props.inBtn ? "20px" : "50px")};
	display: flex;
	& .path {
		stroke: ${(props) =>
			props.initial
				? props.theme.primary
					? props.theme.primary
					: props.intialColorLoader
				: props.theme.primary};
		stroke: ${(props) => (props.inBtn ? "#fff" : props.theme.primary)};
		stroke-linecap: round;
		animation: dash 1.5s ease-in-out infinite;
	}
	@keyframes rotate {
		100% {
			transform: rotate(360deg);
		}
	}
	@keyframes dash {
		0% {
			stroke-dasharray: 1, 150;
			stroke-dashoffset: 0;
		}
		50% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -35;
		}
		100% {
			stroke-dasharray: 90, 150;
			stroke-dashoffset: -124;
		}
	}
`;

const LoaderContainer = styled.div`
	display: flex;
	align-items: center;
	min-height: 100%;
	${(props) =>
		props.full &&
		css`
			justify-content: center;
			min-height: calc(100vh - 337px);
			${respondTo.sm`
      min-height: calc(100vh - 230px);
    `}
		`}
`;

const LoaderOverlay = styled.div`
	width: 100vw;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) =>
		props.theme.overlay ? props.theme.overlay : props.bgOverlay};
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999;
`;

const Loader = (props) => {
	const { show, initial, full } = props;

	const transition = useTransition(show, null, {
		from: {
			opacity: 0,
		},
		enter: {
			opacity: 1,
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			minHeight: "100%",
		},
		leave: {
			opacity: 0,
		},
	});

	let spinner = (
		<StyledLoader viewBox="0 0 50 50" {...props}>
			<circle
				className="path"
				cx="25"
				cy="25"
				r="20"
				fill="none"
				strokeWidth="4"
			/>
		</StyledLoader>
	);

	let loader = <LoaderContainer {...props}>{spinner}</LoaderContainer>;

	if (initial) {
		loader = <LoaderOverlay {...props}>{spinner}</LoaderOverlay>;
	}

	return (
		<React.Fragment>
			{transition.map(({ item, props: animation, key }) => {
				return (
					item && (
						<animated.div key={key} style={animation}>
							{loader}
						</animated.div>
					)
				);
			})}
		</React.Fragment>
	);
};

Loader.defaultProps = {
	bgOverlay: "#292e32",
	intialColorLoader: "#fff",
};

Loader.propTypes = {
	initial: PropTypes.bool,
	show: PropTypes.bool,
};

export default Loader;
