import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Code from "../Code";
import Prize from "../Prize";
import Form from "../../Form";
import ResultDynamic from "../ResultDynamic";
import Fade from "../../../Fade";
import { Button } from "../../../Ui";
import { getElement } from "../../../../utils/utils";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

const ResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div``;

const GameWrapper = styled.div`
  padding: 40px 20px;
  height: auto;
  max-width: 450px;

  h3 {
    text-align: center;
    font-size: 28px;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
  }

  span {
    display: block;
    padding: 20px;
    margin: 0 -20px;
    font-size: 18px;
    color: ${(props) => props.theme.text.primary};
  }
`;

const GameImage = styled.img`
  width: 100%;
  height: auto;

  margin-top: 20px;
`;

const ButtonLink = styled.div`
  margin-top: 25px;
`;

const LoserText = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const ResultGame = (props) => {
  const {
    g: {
      id,
      interaction: { data: rules },
      log,
      log: { steps, won, code, error, prize, form: formResponse },
      i18l,
      form,
    },
    lang,
    played,
    action,
    action: { cleanData },
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    return () => {
      cleanData(id);
    };
  }, []);

  let theData = {};
  let theProfileData = action.data ? getElement(action.data, id) : {};

  if (played) {
    theData = steps.interaction.data;
  } else {
    theData = theProfileData.data ? theProfileData.data : null;
  }

  let rulesData = JSON.parse(rules);
  let logData = theData ? JSON.parse(theData) : null;

  let theCodeData = {};

  if (theProfileData) {
    theCodeData = {
      code: theProfileData.code,
      error: theProfileData.error,
    };
  } else {
    theCodeData = { code: code, error: error };
  }

  let playslog = theProfileData ? theProfileData.playslog : log.playslog;
  let userWon = theProfileData ? theProfileData.won : won;
  let thePrize = theProfileData ? theProfileData.prize : log.prize;
  let confirm = theProfileData ? theProfileData.confirm : log.confirm;
  let withcode = theProfileData ? theProfileData.withcode : log.withcode;

  const {
    settings: {
      aspect: { bgresult, bgresultcolor, loosecard, wincard },
      socialShare,
      summary,
    },
  } = rulesData;

  let resultDynamic = null;
  if (userWon) {
    resultDynamic = "win";
  } else {
    resultDynamic = "loose";
  }

  useEffect(() => {
    if (userWon) {
      if (userWon === 0) {
        ReactGA.event({
          category: "Game",
          action: "Perdita",
        });
      } else if (userWon === 1) {
        ReactGA.event({
          category: "Game",
          action: "Vincita",
        });
      }
    }
  }, [userWon]);

  let dynamicData = i18l[resultDynamic];

  let profileData = logData
    ? {
        socialShare,
        summary,
        profile: logData.profile,
        attempts: logData.attempts,
        nAttempts: logData.nAttempts,
      }
    : {};

  const [isConvalidaOpen, setIsConvalidaOpen] = useState(false);

  return (
    <ResultContainer bg={bgresult} bgcolor={bgresultcolor}>
      {isConvalidaOpen && userWon && form.type === "post" ? (
        <Form
          form={form}
          formResponse={formResponse}
          lang={lang}
          id={id}
          playslog={playslog}
          played={played}
        />
      ) : (
        <GameWrapper>
          <h3>{i18l.info.title}</h3>
          <Fade show={true}>
            {won !== null || theProfileData.won !== null ? (
              <ResultDynamic dynamicData={dynamicData} userWon={userWon} />
            ) : null}
          </Fade>
          {userWon ? (
            <GameImage src={wincard}></GameImage>
          ) : (
            <GameImage src={loosecard}></GameImage>
          )}

          {userWon ? (
            <Button upper fullwidth onClick={() => setIsConvalidaOpen(true)}>
              Ritira il tuo premio
            </Button>
          ) : (
            <>
              <LoserText>{dynamicData.content}</LoserText>
              <ButtonLink>
                <Link to="/">
                  <Button upper fullwidth>
                    Torna alla home
                  </Button>
                </Link>
              </ButtonLink>
            </>
          )}
          {!confirm && withcode ? (
            <div>Attendi la validazione dello scontrino</div>
          ) : null}
          {theCodeData.code !== null || theCodeData.error !== null ? (
            <Code theCodeData={theCodeData} />
          ) : null}
        </GameWrapper>
      )}
    </ResultContainer>
  );
};

export default ResultGame;
