import React, { useEffect } from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import {
	Wrapper,
	Section,
	Loader,
	Typography,
	CustomContainer,
} from "../../components/Ui";
import SingleAward from "../../components/SingleAward";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";

const AwardsContainer = styled(CustomContainer)`
	background-color: #efefef;
	/* display: flex;
    flex-direction: column; */
`;

const UserInfoContainer = styled.div`
	padding: 40px 0 16px;
	text-align: center;
	& p {
		margin-bottom: 6px;
		text-align: center;
	}
`;

const CustomTypo = styled.div`
	margin-bottom: 16px;
`;

const CustomTypo2 = styled.div`
	margin-bottom: 28px;
`;

const UserCodes = styled.div``;

const NoCode = styled.div`
	margin: 60px 0;
	text-align: center;
`;

const Awards = (props) => {
	const { getUserInfo, loading, userInfo, wins } = props;

	const [t] = useTranslation();

	const style_1 = useSpring({
		to: { opacity: 1, transform: "translate3d(0,0px,0)" },
		from: { opacity: 0, transform: "translate3d(0,5px,0)" },
	});

	let userinfo = null;

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getUserInfo();
		}

		return () => {
			mounted = false;
			// userinfo = null;
		};
	}, []);

	if (userInfo && !loading) {
		userinfo = (
			<React.Fragment>
				<div>
					<Wrapper>
						<UserInfoContainer>
							<CustomTypo>
								<Typography as="p" type="infolabel" ls="8">
									{t("auth.user")}
								</Typography>
								<Typography
									as="p"
									type="middlesc"
									fl
								>{`${userInfo.firstname} ${userInfo.lastname}`}</Typography>
							</CustomTypo>
							<CustomTypo2>
								<Typography as="p" type="infolabel" ls="8">
									{t("auth.email")}
								</Typography>
								<Typography
									as="p"
									type="middlep"
								>{`${userInfo.email}`}</Typography>
							</CustomTypo2>
							<Typography as="p" type="infolabel" ls="8">
								{t("app.myawards")}
							</Typography>
						</UserInfoContainer>
					</Wrapper>
					<UserCodes>
						{wins.length > 0 ? (
							wins.map((win) => (
								<SingleAward
									key={win.id}
									code={win.code}
									promo={win.promotion}
									game={win.game}
									date={win.datetime}
									prize={win.prize}
								/>
							))
						) : (
							<NoCode>
								<Typography as="p" type="middlep" align="center">
									{t("app.nocode")}
								</Typography>
							</NoCode>
						)}
					</UserCodes>
				</div>
			</React.Fragment>
		);
	}

	return (
		<AwardsContainer>
			{loading ? <Loader show={loading} full /> : userinfo}
		</AwardsContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		userLogged: state.user.userLogged,
		userInfo: state.user.user.userinfo,
		loading: state.user.loading,
		wins: state.user.user.wins,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserInfo: () => dispatch(actionCreators.getUserInfo()),
		getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Awards);
