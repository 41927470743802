import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import NavigationItem from "./NavigationItem/NavigationItem";
import { Typography } from "../../Ui";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const NavContainer = styled.div``;

const NavigationItems = (props) => {
  const { isLogged, multipromo } = props;

  const [t] = useTranslation();

  const promoId = useSelector((state) => state.promo.promoinfo.id);

  const [promoN, setPromoN] = useState();

  useEffect(() => {
    if (promoId) {
      setPromoN(promoId);
    }
  }, [promoId]);

  const date = new Date();
  const currentDay = date.getDay();

  return (
    <NavContainer>
      <ul>
        {multipromo ? (
          <>
            <NavigationItem link="/" active="true">
              <Typography type="bodyCopy">{t("app.allthepromo")}</Typography>
            </NavigationItem>
          </>
        ) : null}
        <NavigationItem link="/" active="true">
          <Typography type="bodyCopy">{t("header.homepage")}</Typography>
        </NavigationItem>
        <NavigationItem link={`/promo/game/${promoN}`}>
          <Typography type="bodyCopy">{t("header.play")}</Typography>
        </NavigationItem>
        <NavigationItem link={`/area-partner`}>
          <Typography type="bodyCopy">{t("header.areapartner")}</Typography>
        </NavigationItem>
        {isLogged ? (
          <>
            <NavigationItem link="/user/profile">
              <Typography type="bodyCopy">{t("header.profile")}</Typography>
            </NavigationItem>
            <NavigationItem link="/contact">
              <Typography type="bodyCopy">{t("header.contact")}</Typography>
            </NavigationItem>
          </>
        ) : null}
      </ul>
    </NavContainer>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
