import React from "react";
import PropTypes from "prop-types";
import DynamicText from "./DynamicText/DynamicText";
import { theme } from "../../../theme/theme";

const textStyles = theme.textStyles;

const Typography = (props) => {
  return (
    <DynamicText {...props} textStyles={textStyles}>
      {props.children}
    </DynamicText>
  );
};

Typography.propTypes = {
  children: PropTypes.any,
};

export default Typography;
