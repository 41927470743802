import React from "react";
import PropTypes from "prop-types";
import { Typography } from "../Ui";
import { useTranslation } from "react-i18next";

const ErrorInModal = (props) => {
	const [t] = useTranslation();

	const { error } = props;

	let e = error;

	if (typeof error === "object" && error !== null) {
		e = error.error;
	}

	return (
		<Typography as="p" align="center">
			{error && error}
		</Typography>
	);
};

ErrorInModal.propTypes = {
	error: PropTypes.any,
};

export default ErrorInModal;
