import React from "react";
import styled from "styled-components";
import { Typography } from "../../";

const LabelContainer = styled.label`
	display: block;
	cursor: pointer;
	color: ${(props) => props.theme.text.primary}
	margin-bottom: 5px;
	margin-left: 7px;
	& span {
		display: block;
	}
`;

const Label = (props) => {
	let config = null;

	if (props.inModal) {
		config = false;
	} else {
		config = true;
	}

	return (
		<LabelContainer {...props}>
			<Typography secondary={config}>
				{props.children}
			</Typography>
		</LabelContainer>
	);
};

export default Label;
