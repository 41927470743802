import React, { useEffect, useState } from "react";
import { Redirect, Route, Router, Switch, NavLink } from "react-router-dom";
import { connect } from "react-redux";

import { createBrowserHistory } from "history";
import { LastLocationProvider } from "react-router-last-location";

import AuthLayout from "../layouts/AuthLayout";
import BaseLayout from "../layouts/BaseLayout";

import ReactGA from "react-ga";
import styled from "styled-components";

import Login from "../views/Login";
import Register from "../views/Register";
import RecoveryPassword from "../views/RecoveryPassword";
import ResetPassword from "../views/ResetPassword";
import Profile from "../views/Profile";
import Initiative from "../views/Initiative";
import UserActivated from "../views/UserActivated";
import Game from "../views/Game";
import Oauth from "../views/Oauth";
import Panel from "../views/Panel";
import Awards from "../views/Awards";
import NotFound from "../views/NotFound";
import Contact from "../views/Contact";
import HowToWork from "../views/HowToWork";
import AreaPartner from "../views/AreaPartner";

import { Link } from "react-router-dom";
import { Modal, Button } from "../components/Ui";

const history = createBrowserHistory();

const ConvalidContent = styled.div`
  text-align: center;

  p {
    line-height: 1.4;
  }

  a {
    margin: 15px 0;
    display: block;
  }
`;

const PrivateRoute = ({ component: Component, layoutCmp: Layout, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isLogged !== null ? (
          <Layout {...props} {...rest} cmp={Component} />
        ) : (
          <Redirect
            to={{
              pathname: !rest.ownLogin ? "/" : "/auth/login",
              state: {
                from: rest.location.pathname,
                isLogged: rest.isLogged,
              },
            }}
          />
        )
      }
    />
  );
};

const Routes = (props) => {
  const { isLogged, ownLogin, multipromo, userWin, promoId } = props;

  history.listen((location) => {
    let pathToGA = location.pathname;
    if (pathToGA === "/" && isLogged != null) {
      window.ga("set", "page", "/logged");
    } else {
      window.ga("set", "page", pathToGA);
    }
    window.ga("send", "pageview");
  });

  useEffect(() => {
    if (isLogged != null) {
      if (window.location.pathname === "/") {
        window.ga("set", "page", "/logged");
      }
    } else {
      window.ga("set", "page", window.location.pathname);
    }
    window.ga("send", "pageview");
  }, [isLogged]);

  const [showCM, setShowCM] = useState(false);

  useEffect(() => {
    if (userWin) {
      setShowCM(true);
    }
  }, [userWin]);

  let ownRoutes = null;

  const closeCMHandler = () => {
    setShowCM(false);
  };

  if (ownLogin) {
    ownRoutes = (
      <React.Fragment>
        <Route
          path="/auth/login"
          exact
          render={(route) => <AuthLayout cmp={Login} {...route} />}
        />
        <Route
          path="/auth/recovery-password"
          exact
          render={(route) => <AuthLayout cmp={RecoveryPassword} {...route} />}
        />
        <Route
          path="/auth/register"
          exact
          render={(route) => <AuthLayout cmp={Register} {...route} />}
        />
        <Route
          path="/activate/:id"
          exact
          render={(route) => <AuthLayout cmp={UserActivated} {...route} />}
        />
        <Route
          path="/reset-password/:onetimetoken"
          exact
          render={(route) => <AuthLayout cmp={ResetPassword} {...route} />}
        />
      </React.Fragment>
    );
  }

  return (
    <Router history={history}>
      {showCM ? (
        <Modal show={showCM} close={() => closeCMHandler()}>
          <ConvalidContent>
            <p>Hai un premio da convalidare?</p>
            <Link
              to={`/promo/game/${promoId}`}
              onClick={() => closeCMHandler()}
            >
              <Button>Si, voglio convalidarlo</Button>
            </Link>
          </ConvalidContent>
        </Modal>
      ) : null}
      <LastLocationProvider>
        <Switch>
          <Route
            path="/"
            exact
            render={(route) => (
              <BaseLayout cmp={multipromo ? null : Initiative} {...route} />
            )}
          />
          <Route
            path="/rules"
            exact
            render={() => <BaseLayout cmp={HowToWork} />}
          />
          <Route
            path="/promo/:initiative"
            exact
            render={(route) => <BaseLayout cmp={Initiative} {...route} />}
          />
          <Route
            path="/oauth/:connect"
            exact
            render={(route) => <Oauth {...route} />}
          />
          <Route
            path="/tags/:tag"
            exact
            render={(route) => <BaseLayout cmp={null} {...route} />}
          />
          <Route path="/panel" exact component={Panel} />
          <PrivateRoute
            path="/promo/:initiative/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            type="gamelayout"
          />
          <PrivateRoute
            path="/promo/game/:id"
            exact
            component={Game}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
            isGame={true}
            type="gamelayout"
          />
          ;
          <PrivateRoute
            path="/user/profile"
            exact
            component={Profile}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/user/awards"
            exact
            component={Awards}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/contact"
            exact
            component={Contact}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          <PrivateRoute
            path="/area-partner"
            exact
            component={AreaPartner}
            layoutCmp={BaseLayout}
            isLogged={props.isLogged}
            ownLogin={ownLogin}
          />
          {ownRoutes}
          <Route component={NotFound} />
        </Switch>
      </LastLocationProvider>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return {
    promoId: state.promo.viewer.id,
    userWin: state.user.user.has_pending_play,
    isLogged: state.auth.token,
    ownLogin: state.app.config.ownlogin.active,
    multipromo: state.app.config.settings.multipromo,
  };
};

export default connect(mapStateToProps)(Routes);
