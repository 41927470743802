import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { MechanicGame, MechanicGoToGame, MechanicBox } from "./Mechanics";

const RouteGames = (props) => {
	const {
		g: {
			ingame,
			interaction: { page },
		},
	} = props;

	let content = null;

	if (ingame) {
		content = <MechanicGame {...props} />;
	} else {
		if (page) {
			content = <MechanicGoToGame {...props} />;
		} else {
			content = <MechanicBox {...props} />;
		}
	}

	return <React.Fragment>{content}</React.Fragment>;
};

RouteGames.propTypes = {
	withpage: PropTypes.bool,
	ingame: PropTypes.bool,
};

export default RouteGames;
