import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Typography } from "../../../Ui";
import ErrorInModal from "../../../ErrorInModal";
import Board from "./Board";
import DisplayCode from "./DisplayCode";
import Fade from "../../../Fade";

const DisplayCodeContainer = styled.div`
	margin-top: 24px;
`;

const DefaultCodeContainer = styled.div`
	margin-top: 0px;
`;

const Code = (props) => {
	const {
		theCodeData: { error, code },
	} = props;

	const [t] = useTranslation();

	/*return (
		<Board>
			<Fade show={true}>
				<DefaultCodeContainer>
					<Typography as="p" type="text" align="center">
						{t("games.getcoderesponse")}
					</Typography>
					<DisplayCodeContainer>
						{code ? <DisplayCode small code={code} /> : null}
						{error ? <ErrorInModal error={error} /> : null}
					</DisplayCodeContainer>
				</DefaultCodeContainer>
			</Fade>
		</Board>
	);*/

	return false;
};

export default Code;
