import styled from 'styled-components';
import PropTypes from 'prop-types';
import { respondTo } from '../../../theme/mixin';

const Section = styled.section`
  width: 100%;
  margin-bottom: ${props => (props.smallBottom ? '36px' : '45px')};
  margin-bottom: ${props => (props.noBott ? '0px' : '45px')};
  ${respondTo.sm`
    margin-bottom: ${props => (props.smallBottom ? '50px' : '90px')}; 
    margin-bottom: ${props => (props.noBott ? '0px' : '20px')}; 
  `}
  ${respondTo.md`
    margin-bottom: ${props => (props.smallBottom ? '50px' : '90px')}; 
    margin-bottom: ${props => (props.noBott ? '0px' : '30px')}; 
  `}
`;

Section.propTypes = {
  smallBottom: PropTypes.bool,
  noBott: PropTypes.bool
};

export default Section;
