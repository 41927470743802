import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { recoveryPasswordForm } from "../../formsConfig/formsConfig";
import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Button, Input, Loader, Modal, SmallLink } from "../Ui";
import ErrorInModal from "../ErrorInModal";
import styled from "styled-components";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { respondTo } from "../../theme/mixin";

const AccediBtnContainer = styled.div``;

const Disclaimer = styled.div`
  margin-top: 20px;
  color:#6366697f;
`;

const AccessWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 6px #63666919;
  border-radius: 20px;
  padding: 20px;

  & input {
    background-color: #63666919;
    margin-bottom: 20px;
  }

  input::placeholder {
    color: #6366697f;
  }
`;

const RecoveryPasswordForm = (props) => {
  const inputRef = useRef();

  const { loading, recoveryPassword, error, clearError, recovery } = props;

  const [showModal, setShowModal] = useState(false);

  const [t] = useTranslation();
  const recoveryForm = recoveryPasswordForm(t("auth.insertemail"), inputRef);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (error !== null) {
      if (error) {
        setShowModal(true);
      }
    }
  }, [error]);

  const recoverySubmit = () => {
    recoveryPassword(formData.email.value);
  };
  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
  } = useForm(recoverySubmit, recoveryForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
      />
    );
  });

  return (
    <div>
      <AccessWrapper>
        <form onSubmit={formSubmitHandler}>
          {inputs}
          <AccediBtnContainer>
            <Button type="submit" upper fullwidth disabled={loading}>
              {loading ? <Loader inBtn show={true} /> : t("auth.sendemail")}
            </Button>
          </AccediBtnContainer>
          <Modal show={recovery} close={modalHandler}>
            {t("auth.emailsentlabel")}
          </Modal>
          <Modal show={showModal} close={modalHandler}>
            <ErrorInModal error={error} />
          </Modal>
        </form>
      </AccessWrapper>
      <Disclaimer>Per informazioni relative all’iniziativa e alle modalità di partecipazione contatta il servizio clienti all’indirizzo <a href="mailto:accendilmercoledi@nextmailing.it">accendilmercoledi@nextmailing.it</a></Disclaimer>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loadingRecovery,
    error: state.auth.errorRecovery,
    recovery: state.auth.recovery,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    recoveryPassword: (email) =>
      dispatch(actionCreators.recoveryPassword(email)),
    clearError: () => dispatch(actionCreators.recoveryPasswordClearError()),
  };
};

RecoveryPasswordForm.propTypes = {
  loading: PropTypes.bool,
  recoveryPassword: PropTypes.func.isRequired,
  error: PropTypes.number,
  clearError: PropTypes.func,
  recovery: PropTypes.bool,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecoveryPasswordForm);
