import React from "react";
import { UploadReceipt } from "../Interactions";

const PreInteraction = (props) => {
  const { onShowInteractionHandler, preinteraction } = props;

  let content = null;

  switch (preinteraction.type) {
    case "upload-receipt":
      content = (
        <UploadReceipt
          typeInteraction="preinteraction"
          onShowInteractionHandler={onShowInteractionHandler}
          {...props}
        />
      );
      break;
    case "default":
      return content;
  }

  return content;
};

export default PreInteraction;

// se è pre mando una props al upload scontrino in modo tale che chiami lo stato dell sho interactions se è essa stessa un'interaction allora chiamo la dynamic
