import * as actionTypes from "../actions/actionTypes";

const initialState = {
  user: {},
  loading: true,
  error: null,
  activatedloading: true,
  activated: false,
  activatederror: null,
  userLogged: null,
  updateModal: false,
  updateError: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.GET_USER_INFO_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.user,
      };
    case actionTypes.GET_USER_INFO_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case actionTypes.USER_ACTIVATION_START:
      return {
        ...state,
        activatedloading: true,
      };
    case actionTypes.USER_ACTIVATION_SUCCESS:
      return {
        ...state,
        activated: true,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_ERROR:
      return {
        ...state,
        activatederror: action.error,
        activatedloading: false,
      };
    case actionTypes.USER_ACTIVATION_CLEAR_ERROR:
      return {
        ...state,
        activatedloading: false,
        activatederror: null,
      };
    case actionTypes.EDIT_USER_SUCCESS:
      return {
        ...state,
        updateModal: true,
        user: { userinfo: action.user },
      };
    case actionTypes.EDIT_USER_ERROR:
      return {
        ...state,
        updateError: true,
      };
    case actionTypes.CLOSE_MODAL_EDIT:
      return {
        ...state,
        updateModal: false,
      };
    default:
      return state;
  }
};

export default userReducer;
