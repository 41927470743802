import * as actionTypes from "./actionTypes";
import customaxios from "../../config/axios-refresh-token";

const getCmsFooterStart = () => {
  return {
    type: actionTypes.GET_CMS_FOOTER_START,
  };
};

const getCmsFooterSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_FOOTER_SUCCESS,
    content,
  };
};

const getCmsFooterError = (error) => {
  return {
    type: actionTypes.GET_CMS_FOOTER_ERROR,
    error,
  };
};

export const getCmsFooter = () => {
  let endpoint = `cms/promo/get/footer`;
  return (dispatch) => {
    dispatch(getCmsFooterStart());
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsFooterSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          dispatch(getCmsFooterError(error));
          reject(error);
        });
    });
  };
};

export const getCmsHow = () => {
  let endpoint = `cms/promo/get/howItWorks`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsHowSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsHowSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_HOW_SUCCESS,
    content,
  };
};

export const getCmsCoupon = () => {
  let endpoint = `cms/promo/get/coupon`;
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      customaxios
        .get(endpoint)
        .then((result) => {
          dispatch(getCmsCouponSuccess(result.data));
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
};

const getCmsCouponSuccess = (content) => {
  return {
    type: actionTypes.GET_CMS_COUPON_SUCCESS,
    content,
  };
};
