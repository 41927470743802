import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Loader } from "../../components/Ui";
import AuthTemplate from "../../templates/AuthTemplate";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import { getOneTimeToken } from "../../utils/utils";

import * as actionCreators from "../../store/actions";

const ActiveConfirm = styled.p`
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  color: ${(props) => props.theme.secondary};
`;

const UserActivated = (props) => {
  const { logo, userActivation, error, loading, clearError } = props;

  const [t] = useTranslation();

  useEffect(() => {
    userActivation(getOneTimeToken());
    return () => {
      clearError();
    };
  }, []);

  return (
    <AuthTemplate logo={logo}>
      {loading ? (
        <Loader show={true} />
      ) : (
        <ActiveConfirm>
          {error ? t(`error.${error}`) : t("auth.useractivated")}
        </ActiveConfirm>
      )}
      <Link to="/auth/login">
        <Button upper fullwidth>{t("auth.signinlabel")}</Button>
      </Link>
    </AuthTemplate>
  );
};

const mapStateToProps = (state) => {
  return {
    error: state.user.activatederror,
    loading: state.user.activatedloading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userActivation: (id) => dispatch(actionCreators.userActivation(id)),
    clearError: () => dispatch(actionCreators.userActivationClearError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserActivated);
