import React from "react";
import { Button } from "../Ui";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { respondTo } from "../../theme/mixin";
import parse from "html-react-parser";

const CardContainer = styled.div`
  padding: ${(props) => (props.nopadding ? "0" : "0 15px")};
  margin-bottom: 20px;
  margin-top: ${(props) => (props.margintop ? `${props.margintop}px` : "0")};

  ${respondTo.sm`
    width: ${(props) => (props.mobwidth ? `${props.mobwidth}%` : "100%")};
  `}

  .card-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background-color: #4e9cbe;
    border-radius: ${(props) => (props.footer ? "20px 20px 0 0" : "20px")};

    .left {
      width: 52%;
      padding: 15px 0 15px 15px;
      color: #fff;
      text-align: center;

      h3 {
        text-transform: uppercase;
        font-size: 26px;
        font-weight: 500;

        ${respondTo.sm`
          font-size: 22px;
        `}

        strong {
          font-size: 35px;
          font-weight: 700;

          ${respondTo.sm`
            font-size: 30px;
          `}
        }
      }

      small {
        display: block;
      }

      span {
        color: ${(props) => props.theme.secondary};
        font-size: 56px;
        font-weight: 700;
        display: block;

        ${respondTo.sm`
          margin-top: 5px;
          font-size: 42px;
          line-height: 1;
        `}
      }
    }

    .right {
      width: 48%;
      padding-right: 10px;
    }
  }

  .card-footer {
    padding: 20px;
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    box-shadow: 0 3px 6px #63666919;
  }
`;

const CardCoupon = (props) => {
  const user = useSelector((state) => state.user.user);
  const couponImg = useSelector(
    (state) =>
      state.cms.coupon
        .find((x) => x.name === "coupon")
        .contents.find((x) => x.name === "image").img
  );
  const couponTextUp = useSelector(
    (state) =>
      state.cms.coupon
        .find((x) => x.name === "coupon")
        .contents.find((x) => x.name === "textUp").i18l.content
  );
  const couponTextDown = useSelector(
    (state) =>
      state.cms.coupon
        .find((x) => x.name === "coupon")
        .contents.find((x) => x.name === "textDown").i18l
  );
  const couponTextButton = useSelector(
    (state) =>
      state.cms.coupon
        .find((x) => x.name === "coupon")
        .contents.find((x) => x.name === "textButton").i18l.content
  );

  const { margintop, nopadding, mobwidth, footer } = props;

  return (
    <CardContainer
      footer={footer}
      nopadding={nopadding}
      margintop={margintop}
      mobwidth={mobwidth}
    >
      <div className="card-content">
        <div className="left">
          <h3>{parse(couponTextUp)}</h3>
          <small>
            {user.userinfo
              ? parse(couponTextDown.title)
              : parse(couponTextDown.content)}
          </small>
          <span>{user.userinfo ? user.userinfo.wallet_amount : "50"}€</span>
        </div>
        <div className="right">
          <img src={couponImg} alt="" />
        </div>
      </div>
      {footer && (
        <div className="card-footer">
          <Link to="/area-partner">
            <Button upper fullwidth>
              {parse(couponTextButton)}
            </Button>
          </Link>
        </div>
      )}
    </CardContainer>
  );
};

export default CardCoupon;
