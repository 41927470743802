import * as actionTypes from '../actions/actionTypes';

const initialState = {
  allpromo: [],
  loadingallpromo: true,
  errorallpromo: null,
  hgpromo: [],
  loadinghgpromo: true,
  errorhgpromo: null,
  viewer: {},
  loadingviewer: true,
  errorviewer: null,
  isviewer: false,
  checkInitiative: null,
  checkLoading: false,
  error404: [],
  game: {},
  loadinggame: true,
  errorgame: null,
  gameRetry: false,
  promoinfo: {},
  loadingclienttags: true,
  errorclienttags: null,
  tagsclient: [],
  loadingtagviewer: true,
  tagviewer: {},
  errortagviewer: null
};

const promoReducer = (state = initialState, action) => {
  switch(action.type) {
    case actionTypes.GET_ALL_PROMO_START: {
      return {
        ...state,
        loadingallpromo: true
      }
    }
    case actionTypes.GET_ALL_PROMO_SUCCESS: {
      return {
        ...state,
        loadingallpromo: false,
        allpromo: action.promo
      }
    }
    case actionTypes.GET_ALL_PROMO_ERROR: {
      return {
        ...state,
        loadingallpromo: false,
        errorallpromo: action.error
      }
    }
    case actionTypes.GET_HIGHLIGHTS_PROMO_START: {
      return {
        ...state,
        loadinghgpromo: true
      }
    }
    case actionTypes.GET_HIGHLIGHTS_PROMO_SUCCESS: {
      return {
        ...state,
        loadinghgpromo: false,
        hgpromo: action.promo
      }
    }
    case actionTypes.GET_HIGHLIGHTS_PROMO_ERROR: {
      return {
        ...state,
        loadinghgpromo: false,
        errorhgpromo: action.error
      }
    }
    case actionTypes.GET_PROMO_VIEWER_START: {
      return {
        ...state,
        loadingviewer: true,
        isviewer: false,
        errorviewer: null
      }
    }
    case actionTypes.GET_PROMO_VIEWER_SUCCESS: {
      return {
        ...state,
        loadingviewer: false,
        viewer: action.viewer,
        isviewer: true
      }
    }
    case actionTypes.GET_PROMO_VIEWER_ERROR: {
      return {
        ...state,
        loadingviewer: false,
        isviewer: false,
        errorviewer: action.error
      }
    }
    case actionTypes.GET_PROMO_VIEWER_RESET: {
      return {
        ...state,
        loadingviewer: false,
        errorviewer: null,
        isviewer: false
        //viewer: {}
      }
    }
    case actionTypes.GET_PROMO_INFO_START: {
      return {
        ...state,
        checkInitiative: null,
        checkLoading: true,
        promoinfo: {}
      }
    }
    case actionTypes.GET_PROMO_INFO_LOADING: {
      return {
        ...state,
        checkLoading: true
      }
    }
    case actionTypes.GET_PROMO_INFO_SUCCESS: {
      return {
        ...state,
        checkInitiative: action.status,
        promoinfo: action.promoinfo,
        checkLoading: false
      }
    }
    case actionTypes.GET_PROMO_INFO_ERROR: {
      return {
        ...state,
        checkInitiative: action.error,
        checkLoading: false
      }
    }
    case actionTypes.SET_PROMO_404_ERROR: {
      return {
        ...state,
        error404: state.error404.concat(action.status)
      }
    }
    case actionTypes.RESET_PROMO_404_ERROR: {
      return {
        ...state,
        error404: []
      }
    }
    case actionTypes.GET_GAME_START: {
      return {
        ...state,
        loadinggame: true
      }
    }
    case actionTypes.GET_GAME_SUCCESS: {
      return {
        ...state,
        loadinggame: false,
        game: action.game
      }
    }
    case actionTypes.GET_GAME_ERROR: {
      return {
        ...state,
        loadinggame: false,
        errorgame: action.error
      }
    }
    case actionTypes.SET_GAME_RETRY: {
      return {
          ...state,
          gameRetry: !state.gameRetry
      }
    }
    case actionTypes.GET_PROMO_TAG_START: {
      return {
        ...state,
        loadingclienttags: true,
      }
    }
    case actionTypes.GET_PROMO_TAG_SUCCESS: {
      return {
        ...state,
        loadingclienttags: false,
        tagsclient: action.taginfo
      }
    }
    case actionTypes.GET_PROMO_TAG_ERROR: {
      return {
        ...state,
        loadingclienttags: false,
        errorclienttags: action.error
      }
    }
    case actionTypes.GET_PROMO_TAG_VIEWER_START: {
      return {
        ...state,
        loadingtagviewer: true,
      }
    }
    case actionTypes.GET_PROMO_TAG_VIEWER_SUCCESS: {
      return {
        ...state,
        loadingtagviewer: false,
        tagviewer: action.viewer
      }
    }
    case actionTypes.GET_PROMO_TAG_VIEWER_ERROR: {
      return {
        ...state,
        loadingtagviewer: false,
        errortagviewer: action.error
      }
    }
    default: {
      return state;
    }
  }
}

export default promoReducer;

