import React from "react";
import { useSelector } from "react-redux";
import { Button } from "../../components/Ui";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import parse from "html-react-parser";

const RuleContainer = styled.div`
  ${respondTo.md`
    margin-top: 30px;
    width: 100%;
  `}
  ${respondTo.lg`
    margin-top: 0;
  `}
`;

const RuleBanner = styled.img`
  width: 100%;
  height: auto;

  ${respondTo.md`
    display: none;
  `}
`;

const HomeRule = styled.div`
  display: block;
  width: 100%;
  padding: 0 15px;
  margin-top: 30px;

  ${respondTo.sm`
    margin-bottom: 15px;
    margin-top: 0;
  `}

  .ruleBody {
    background-color: #fff;
    padding: 20px;
    border-radius: 20px;
    border-bottom: 1px solid #c5cbd1;

    .ruleText {
      ${respondTo.sm`
        height: 60px;
        overflow: auto;
      `}
    }

    h3 {
      font-size: 24px;
      color: #636669;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 15px;
    }

    p {
      font-size: 14px;
      color: #636669;
    }

    .rules-btn-container {
      margin: 30px -20px 0 -20px;
      padding: 20px 20px 0 20px;
      border-top: 1px solid #c5cbd1;

      ${respondTo.sm`
        margin-top: 10px;
      `}
    }
  }

  .ruleFooter {
    background-color: #fff;
    border-radius: 0 0 20px 20px;
    padding: 20px;
    box-shadow: 0 3px 6px #63666919;

    p {
      font-size: 14px;

      a {
        color: ${(props) => props.theme.primary};
        text-decoration: underline;
      }
    }
  }
`;

const HowToWork = (props) => {
  const promoImg = useSelector((state) => state.promo.viewer.img_desktop);
  const how = useSelector(
    (state) =>
      state.cms.how
        .find((x) => x.name === "boxFunzionamento")
        .contents.find((x) => x.name === "howContent").i18l
  );

  return (
    <div className="container">
      <RuleContainer>
        <RuleBanner homepage={props.homepage} src={promoImg} />
        <HomeRule>
          <div className="ruleBody">
            <h3>{how.title}</h3>
            <div className="ruleText">{parse(how.content)}</div>
          </div>
        </HomeRule>
      </RuleContainer>
    </div>
  );
};

export default HowToWork;
