import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Menu } from 'styled-icons/boxicons-regular';

const HamburgerContainer = styled.div`
  cursor: pointer;
`;

const Hamburger = props => {
  const { clicked } = props;

  return (
    <HamburgerContainer onClick={clicked}>
      {/* <Menu size={45} /> */}
      <img src={process.env.PUBLIC_URL + "/icons/menu-icon.svg"} alt="" />
    </HamburgerContainer>
  );
};

Hamburger.propTypes = {
  clicked: PropTypes.func
};

export default Hamburger;
