import React from 'react';
import PropTypes from 'prop-types';
import SigninForm from '../SigninForm';
import styled from 'styled-components';
import { Logo } from '../Ui';

const ModalLoginFlowContainer = styled.div`
    max-width: 90%;
    width: 350px;
    margin: auto;
`;

const ModalLoginFlow = (props) => {

    const { logo } = props;

    return (
        <ModalLoginFlowContainer>
            <Logo center logo={logo} />
            <SigninForm inModal />
        </ModalLoginFlowContainer>
    )
}


ModalLoginFlow.propTypes = {
    logo: PropTypes.string
}

export default ModalLoginFlow;
