import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Typography } from "../index";
import GoToGame from "./GoToGame";
import { respondTo } from "../../../theme/mixin";
import { useSpring, animated } from "react-spring";
import { useSelector } from "react-redux";

const ViewerContainer = styled.div`
  padding: 0 15px;
  width: 100%;

  ${respondTo.sm`
    width: 50%;
    padding-left: 0;
    padding-right: 15px;
  `}
`;

const ViewerRelative = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.secondary};
  padding: 15px;
  border-radius: 20px 20px 0 0;

  ${respondTo.sm`
    padding: 0 15px;
  `}
`;

const ViewerBackground = styled(animated.div)`
  width: calc(50% - 10px);
  min-height: 50px;
  position: relative;
  border-radius: 20px;

  ${respondTo.sm`
    margin-bottom: 0;
  `}
`;

const ViewerContent = styled(animated.div)`
  width: calc(50% - 5px);
  margin-left: 15px;

  ${respondTo.sm`
    display: flex;
    align-items: center;
    padding: 15px 0;
  `}
`;

const TitleContainer = styled.div`
  margin-bottom: 10px;
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  color: #fff;

  ${respondTo.sm`
    font-size: 26px;
  `}
`;

const TextContainer = styled.div`
  p {
    font-size: 22px;
    color: #fff;

    ${respondTo.sm`
      font-size: 20px;
      line-height: 1.2;
  `}
  }
`;

const ButtonContainer = styled.div`
  border-radius: 0 0 20px 20px;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 3px 6px #63666919;
`;

const LogoPromo = styled.div``;

const Viewer = (props) => {
  const {
    scream,
    description,
    type,
    islogged,
    logo,
    logopromo,
    url,
    cta,
    iscta,
    ctatype,
    dark_text,
    oauths,
    ownlogin,
    oauthinfo,
    idgame,
    pathname,
    multipromo,
  } = props;

  const promoViewer = useSelector((store) => store.promo.viewer);

  const style = useSpring({
    to: { opacity: 1, transform: "translate3d(0,0px,0)" },
    from: { opacity: 0, transform: "translate3d(0,8px,0)" },
  });

  const style2 = useSpring({
    to: { opacity: 1 },
    from: { opacity: 1 },
  });

  return (
    <>
      <ViewerContainer>
        <ViewerRelative>
          <ViewerBackground style={style2} {...props}>
            {logo ? (
              <LogoPromo>
                <img src={logopromo} alt="" />
              </LogoPromo>
            ) : null}
          </ViewerBackground>
          <ViewerContent style={style}>
            <TextContainer>
              <TitleContainer>{promoViewer.i18l.title}</TitleContainer>
              <Typography
                as="p"
                type="vbody"
                switchcolor
                dark_text={dark_text}
                dangerouslySetInnerHTML={{ __html: promoViewer.i18l.content }}
              />
            </TextContainer>
          </ViewerContent>
        </ViewerRelative>
        {iscta ? (
          <ButtonContainer>
            <GoToGame
              isLogged={islogged}
              logo={logo}
              url={url}
              cta={cta}
              ctatype={ctatype}
              oauths={oauths}
              ownlogin={ownlogin}
              oauthinfo={oauthinfo}
              idgame={idgame}
              pathname={pathname}
              multipromo={multipromo}
            />
          </ButtonContainer>
        ) : null}
      </ViewerContainer>
    </>
  );
};

Viewer.propTypes = {
  scream: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  logopromo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  iscta: PropTypes.number,
  ctatype: PropTypes.string,
};

export default Viewer;
