import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "../..";
import LoginBtn from "../../../LoginBtn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ErrorInModal from "../../../ErrorInModal";

const GoToGame = (props) => {
  const [isWedModal, setIsWedModal] = useState(false);
  const [t] = useTranslation();
  const {
    isLogged,
    logo,
    url,
    cta,
    ctatype,
    oauths,
    ownlogin,
    oauthinfo,
    idgame,
    pathname,
    multipromo,
  } = props;

  useEffect(() => {
    return () => {
      setIsWedModal(false);
    };
  }, []);

  const closeWedModal = () => {
    setIsWedModal(false);
  };

  let content = null;

  const date = new Date();
  const currentDay = date.getDay();

  if (currentDay > 100) {
    content = (
      <Button upper fullwidth onClick={() => setIsWedModal(true)}>
        {cta ? cta : t("app.play")}
      </Button>
    );
  } else if (ctatype === "url") {
    content = (
      <a href={url} target="_blank">
        <Button upper>{cta ? cta : t("app.visitsite")}</Button>
      </a>
    );
  } else {
    content =
      isLogged && ctatype === "game" ? (
        <Link
          to={{
            pathname: multipromo
              ? `${pathname}/game/${idgame}`
              : `/promo/game/${idgame}`,
            state: { cid: "boxgotogame-viewer" },
          }}
        >
          <Button upper fullwidth>
            {cta ? cta : t("app.play")}
          </Button>
        </Link>
      ) : ownlogin ? (
        <Link to="/auth/login">
          <Button upper fullwidth>
            {cta ? cta : t("app.play")}
          </Button>
        </Link>
      ) : (
        <LoginBtn
          label={cta ? cta : t("app.play")}
          ownlogin={ownlogin}
          oauths={oauths}
          oauthinfo={oauthinfo}
          disabled={currentDay !== 3 ? true : false}
        />
      );
  }

  return (
    <React.Fragment>
      {content}
      {isWedModal && (
        <Modal show={isWedModal} close={closeWedModal}>
          <ErrorInModal error={"Attendi il mercoledì per provare a vincere!"} />
        </Modal>
      )}
    </React.Fragment>
  );
};

GoToGame.propTypes = {
  isLogged: PropTypes.string,
  logo: PropTypes.string,
  url: PropTypes.string,
  cta: PropTypes.string,
  ctatype: PropTypes.string,
};

export default React.memo(GoToGame);
