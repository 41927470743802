import React from "react";
import styled from "styled-components";
import { animated, useSpring } from "react-spring";
import { useTranslation } from "react-i18next";
import { Wrapper } from "../Ui";

const AwardContainer = styled.div`
	background-color: #e1e5e8;
	padding: 20px 0;
	margin-bottom: 10px;
`;
const AwardP = styled.p`
	margin-bottom: 8px;
`;
const AwardSpan = styled.span`
	font-weight: bold;
`;

const SingleAward = (props) => {
	const { code, promo, game, date, prize } = props;

	const [t] = useTranslation();

	const style = useSpring({
		to: { transform: "translate3d(0px,0px,0px)" },
		from: { transform: "translate3d(-5px,0px,0px)" },
	});

	return (
		<AwardContainer>
			<Wrapper small>
				<animated.div style={style}>
					<AwardP>
						<AwardSpan>{t("awards.prize")}</AwardSpan>: {prize}
					</AwardP>
					{code ? (
						<AwardP>
							<AwardSpan>{t("awards.code")}</AwardSpan>: {code}
						</AwardP>
					) : null}
					<AwardP>
						<AwardSpan>{t("awards.promo")}</AwardSpan>: {promo}
					</AwardP>
					<AwardP>
						<AwardSpan>{t("awards.game")}</AwardSpan>: {game}
					</AwardP>
					<AwardP>
						<AwardSpan>{t("awards.date")}</AwardSpan>: {date}
					</AwardP>
				</animated.div>
			</Wrapper>
		</AwardContainer>
	);
};

export default SingleAward;
