import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Button } from "../Ui";

const ModalContainer = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => props.theme.primary};
  z-index: 100;
`;

const ModalClose = styled.img`
  position: fixed;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const ModalContent = styled.div`
  background-color: #fff;
  border-radius: 20px;
  padding: 20px;
  color: ${(props) => props.theme.secondary}
  width: 100%;
  max-width: 500px;

  .modal-header {
    margin-bottom: 10px;

    h3 {
      font-size: 24px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }

  .modal-footer {
    border-top: 1px solid rgba(0, 0, 0, 0.15);
    margin: 20px -20px 0 -20px;
    padding: 20px 20px 0 20px;
  }
`;

const ModalMessage = (props) => {
  return (
    <ModalContainer>
      <ModalClose
        onClick={props.closed}
        src={process.env.PUBLIC_URL + "/icons/close-modal.svg"}
      ></ModalClose>
      <ModalContent>
        <div className="modal-header">
          <h3>{props.title}</h3>
        </div>
        <div className="modal-body">{props.children}</div>
        <div className="modal-footer">
          <Link to={props.link ? props.link : "/"}>
            <Button upper fullwidth>
              {props.button ? props.button : "Torna alla homepage"}
            </Button>
          </Link>
        </div>
      </ModalContent>
    </ModalContainer>
  );
};

export default ModalMessage;
