import React from 'react'
import PropTypes from 'prop-types'
import { Modal } from '../Ui';
import ErrorInModal from '../ErrorInModal';

const SessionExpired = props => {

    const {
        isExpired
    } = props;

    const closeModalHandler = () => {
        window.location.reload();
    }

    return <Modal show={isExpired} close={closeModalHandler}><ErrorInModal error="Sessione scaduta" /></Modal>
}

SessionExpired.propTypes = {
    isExpired: PropTypes.bool
}

export default SessionExpired
