import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./custom.css";
import styled from "styled-components";
import { deltaDate } from "../../../../utils/utils";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import it from "date-fns/locale/it";

registerLocale("it", it);

const DatepickerCustom = styled(DatePicker)`
  border: 1px solid
    ${(props) =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};
  outline: none;
  width: 100%;
  height: 50px;
  background-color: #63666919;
  border-radius: 15px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.primary};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

const Datepicker = (props) => {
  const {
    elementConfig: { placeholder },
    validation: { maxDate, minDate },
  } = props;

  let dateFormat = "MM/dd/yyyy";

  if (props.lang === "it") {
    dateFormat = "dd/MM/yyyy";
  }

  const onkeydown = (e) => {
    if (e.keyCode === 8) {
      e.preventDefault();
      return false;
    }
  };

  const maxdate = props.own_login_adult
    ? deltaDate(new Date(), 0, 0, -18)
    : maxDate === "today"
    ? new Date()
    : maxDate;

  const mindate = minDate === "today" ? new Date() : minDate;

  return (
    <DatepickerCustom
      dateFormat={dateFormat}
      maxDate={maxdate}
      minDate={mindate}
      locale={props.lang}
      onKeyDown={onkeydown}
      showMonthDropdown
      showYearDropdown
      dropdownMode="select"
      placeholderText={placeholder}
      {...props}
    />
  );
};

export default Datepicker;
