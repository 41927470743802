import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "../Ui";
import { respondTo } from "../../theme/mixin";
import { SingleExternalOauth } from "../Oauths";
import styled from "styled-components";

const LoginBtn = (props) => {
  const { label, ownlogin, oauths, oauthinfo } = props;

  const LoginBtnContainer = styled.div`
    margin-top: 15px;
    ${respondTo.sm`
        margin-top: 0;
    `}
  `;


  let content = null;

  if (oauths !== undefined) {
    if (oauthinfo !== null) {
      if (ownlogin) {
        content = (
          <LoginBtnContainer>
            <Link to="/auth/login">
              <Button upper small>{label}</Button>
            </Link>
          </LoginBtnContainer>
        );
      } else {
        content = (
          // The only option available is to have one direct external login (Es. Stardust play).
          <SingleExternalOauth
            label={label}
            oauths={oauths}
            oauthInfo={oauthinfo}
            small
            isfullwidth={false}
          />
        );
      }
    }
  }

  return <React.Fragment>{content}</React.Fragment>;
};

LoginBtn.propTypes = {
  label: PropTypes.string,
  oauths: PropTypes.array,
  oauthinfo: PropTypes.object,
  ownlogin: PropTypes.number,
};

export default LoginBtn;
