import React from "react";
import PropTypes from "prop-types";
import { CardHandler, UploadReceiptHandler } from "../../Handlers";

const MechanicGame = (props) => {
  const {
    g: { interaction },
  } = props;

  let mechanicgame = null;

  switch (interaction.type) {
    case "card":
      mechanicgame = <CardHandler {...props} />;
      break;
    case "upload-receipt":
      mechanicgame = <UploadReceiptHandler {...props} />;
      break;
  }

  return <React.Fragment>{mechanicgame}</React.Fragment>;
};

MechanicGame.propTypes = {
  interaction: PropTypes.object,
};

export default MechanicGame;
