import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "../../components/Ui";
import Parser from "html-react-parser";
import CardCoupon from "../../components/CardCoupon";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import { useDispatch } from "react-redux";
import * as actionCreators from "../../store/actions";
import ModalMessage from "../../components/ModalMessage";
import ErrorInModal from "../../components/ErrorInModal";
import { Modal } from "../../components/Ui";

const SPContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
  background-color: #e4eaef;

  ${respondTo.md`
    position: relative;
  `}

  .sp {
    position: relative;

    ${respondTo.md`
      margin-top: 60px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      margin-left: -15px;
      margin-right: -15px;
    `}

    .sp-image {
      height: 230px;

      ${respondTo.md`
        width: 50%;
        height: 490px;
        padding: 0 15px;
      `}

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;

        ${respondTo.md`
          border-radius: 20px;
        `}
      }
    }

    .sp-info {
      padding: 0 30px;

      ${respondTo.md`
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 50%;
        padding: 0 15px;
      `}

      .sp-brand {
        width: 105px;
        height: 105px;
        border-radius: 50%;
        background-color: #fff;
        display: flex;
        align-items: center;
        padding: 0 7px;
        box-shadow: 0 3px 6px #63666919;
        z-index: 10;
        position: relative;
        top: -55px;
        left: 50%;
        margin-left: -55px;
        margin-bottom: -50px;

        ${respondTo.md`
          position: static;
          margin: 0;
        `}

        img {
          width: 100%;
        }
      }

      .sp-name {
        margin-top: 20px;
        border-bottom: 1px solid #c5cbd1;
        padding-bottom: 15px;
        margin-bottom: 15px;

        ${respondTo.md`
          margin-left: 30px;
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        `}

        h2 {
          color: ${(props) => props.theme.primary};
          font-size: 36px;
          text-transform: uppercase;
          margin-bottom: 10px;

          ${respondTo.md`
            margin-bottom: 0;
        `}
        }

        span {
          display: block;
          color: ${(props) => props.theme.primary};
          font-size: 14px;
          font-weight: 700;
        }
      }

      .sp-close {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
        background-color: #6366697f;
        border-radius: 50%;

        ${respondTo.md`
          top: 0;
          right: 15px;
        `}
      }

      .sp-description {
        margin: 0 -30px;

        ${respondTo.sm`
          position: relative;
          top: -15px;
          border-top: 1px solid #c5cbd1;
          display: flex;
          padding-top: 30px;
          margin: 0;
        `}

        .sp-text {
          font-size: 14px;
          line-height: 1.5;
          padding: 0 30px;

          ${respondTo.sm`
            width: 50%;
            padding: 0 15px 0 0;
          `}
        }

        .sp-use-coupon {
          margin-top: 30px;
          padding: 0 30px;

          ${respondTo.sm`
            width: 50%;
            padding-left: 15px;
            padding-right: 0;
          `}
        }
      }
    }
  }
`;

const SinglePartner = (props) => {
  const { closed, partner } = props;

  const [modalSuccess, setModalSuccess] = useState(false);
  const [modalError, setModalError] = useState(null);

  const dispatch = useDispatch();

  const coupondHandler = () => {
    let endpoint = `partners/${partner.id}/request/`;
    customaxios
      .post(endpoint)
      .then((result) => {
        setModalSuccess(true);
        dispatch(actionCreators.getUserInfo());
      })
      .catch((error) => {
        setModalError(error.response.data.error);
      });
  };

  const closeModalError = () => {
    setModalError(null);
  };

  return (
    <SPContainer>
      <div className="container">
        <div className="sp">
          <div className="sp-image">
            <img src={partner.main_image} alt="" />
          </div>
          <div className="sp-info">
            <div className="sp-brand">
              <img src={partner.logo} alt="" />
            </div>
            <div className="sp-name">
              <h2>{partner.title}</h2>
              <span>{partner.subtitle}</span>
            </div>
            <div className="sp-close" onClick={closed}>
              <img
                src={process.env.PUBLIC_URL + "/icons/menu-close-white.svg"}
                alt=""
              />
            </div>
            <div className="sp-description">
              <div className="sp-text">{Parser(partner.description)}</div>
              <div className="sp-use-coupon">
                <CardCoupon nopadding />
                <Button upper fullwidth onClick={() => coupondHandler()}>
                  Utilizza coupon
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalSuccess && (
        <ModalMessage closed={() => setModalSuccess(false)}>
          Il voucher è stato inviato al tuo indirizzo email
        </ModalMessage>
      )}
      <Modal show={modalError} close={closeModalError}>
        <ErrorInModal error={modalError} />
      </Modal>
    </SPContainer>
  );
};

export default SinglePartner;
