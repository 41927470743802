import React, { useEffect, useState } from "react";
import {
  Viewer,
  Section,
  Wrapper,
  Loader,
  GenericContent,
  CarouselViewer,
  CustomContainer,
} from "../../components/Ui";
import LoaderViewerContainer from "../../components/Ui/Viewer/LoaderViewerContainer";
import { connect } from "react-redux";
import { getSlug } from "../../utils/utils";
import Games from "../../components/Games";
import Seo from "../../components/Seo";
import ErrorReload from "../../components/ErrorReload";
import NotFound from "../NotFound";
import * as actionCreators from "../../store/actions";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import HowToWork from "../HowToWork";
import { isBrowser } from "react-device-detect";
import CardCoupon from "../../components/CardCoupon";
import AwardsList from "../../components/AwardsList/AwardsList";

const HomeContent = styled.div`
  ${respondTo.lg`
    margin-top: 55px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-right: -15px;
  `}
`;

const HomeBanner = styled.img`
  width: 100%;
  margin-bottom: 20px;
  height: auto;

  ${respondTo.lg`
    width: 50%;
    border-radius: 20px;
    overflow: hidden;
  `}
`;

const HomeSection = styled.div`
  width: 100%;

  ${respondTo.sm`
    display: flex;
    flex-wrap: wrap;
  `}

  ${respondTo.lg`
    width: 50%;
  `}
`;

const Initiative = (props) => {
  const [isValid, setIsValid] = useState(true);
  const [showPromo, setShowPromo] = useState(false);

  const {
    appConfig: {
      settings: { multipromo },
    },
    isLogged,
    logo,
    promoinfo,
    getPromoViewer,
    loadingviewer,
    viewer,
    errorviewer,
    getCmsIntro,
    intro,
    loadingintro,
    errorintro,
    getGames,
    games,
    gamesError,
    loadingGames,
    oauths,
    ownLogin,
    oauthInfo,
    getPromoInfo,
    error404,
    location: { pathname },
  } = props;

  let slug = "get";

  if (multipromo) {
    slug = getSlug();
  }

  let seo = null;
  let idgame = null;

  if (games.length > 0 && games.length === 1) {
    idgame = games[0].id;
  }

  if (Object.entries(promoinfo).length !== 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = promoinfo;
    seo = <Seo title={title} description={description} keywords={keywords} />;
  }

  useEffect(() => {
    setIsValid(true);
    getPromoInfo(
      slug,
      props.history.location.state ? props.history.location.state.cid : ""
    );
    getCmsIntro(slug);
    setShowPromo(true);

    localStorage.setItem("lasturl", window.location.href);
  }, []);

  useEffect(() => {
    if (error404.length === 1) {
      setIsValid(false);
    }
  }, [error404]);

  useEffect(() => {
    getGames(slug, isLogged);
  }, [isLogged]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  let content = null;

  content = (
    <React.Fragment>
      <div className="container">
        <HomeContent>
          {seo}
          <HomeBanner src={viewer.img_desktop} alt="" />

          <HomeSection>
            {loadingviewer ? (
              <LoaderViewerContainer>
                {<Loader show={loadingviewer} />}
              </LoaderViewerContainer>
            ) : errorviewer >= 500 ? (
              <ErrorReload clicked={getPromoViewer} param={slug} />
            ) : (
              <>
                {isBrowser && <HowToWork homepage></HowToWork>}
                <CardCoupon footer mobwidth={50} />
                <Viewer
                  scream={promoinfo.i18l ? promoinfo.i18l.title : ""}
                  description={promoinfo.i18l ? promoinfo.i18l.description : ""}
                  imgdesktop={viewer.img_desktop}
                  imgmobile={viewer.img_mobile}
                  logopromo={viewer.logo}
                  islogged={isLogged}
                  logo={logo}
                  url={viewer.i18l.url}
                  cta={viewer.i18l.calltoaction}
                  ctatype={viewer.calltoaction_type}
                  iscta={viewer.calltoaction}
                  dark_text={viewer.dark_text}
                  oauths={oauths}
                  ownlogin={ownLogin}
                  oauthinfo={oauthInfo}
                  idgame={idgame}
                  pathname={pathname}
                  multipromo={multipromo}
                />
              </>
            )}
          </HomeSection>
          <AwardsList />
        </HomeContent>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {isValid ? (
        showPromo ? (
          <CustomContainer>{content}</CustomContainer>
        ) : (
          <CustomContainer></CustomContainer>
        )
      ) : (
        <NotFound internal type="promo" />
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    appConfig: state.app.config,
    isLogged: state.auth.token,
    logo: state.app.config.logo,
    loadingviewer: state.promo.loadingviewer,
    promoinfo: state.promo.promoinfo,
    promoinfoloading: state.promo.checkLoading,
    viewer: state.promo.viewer,
    errorviewer: state.promo.errorviewer,
    intro: state.cms.intro,
    loadingintro: state.cms.loadingintro,
    errorintro: state.cms.errorintro,
    games: state.game.games,
    gamesError: state.game.error,
    loadingGames: state.game.loadingGames,
    oauths: state.app.config.oauths,
    ownLogin: state.app.config.ownlogin.active,
    oauthInfo: state.app.oauthInfo,
    error404: state.promo.error404,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCmsIntro: (slug) => dispatch(actionCreators.getCmsIntro(slug)),
    getGames: (slug, isLogged) =>
      dispatch(actionCreators.getGames(slug, isLogged)),
    getPromoInfo: (slug, cid) =>
      dispatch(actionCreators.getPromoInfo(slug, cid)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Initiative)
);
