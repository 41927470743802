import React from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { respondTo } from "../../theme/mixin";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";

const AwTitle = styled.h2`
  text-transform: uppercase;
  color: ${(props) => props.theme.primary};
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 15px;

  ${respondTo.md`
    padding: 0`}
`;

const AwardContent = styled.div`
  width: 100%;
  margin: 0 -10px;
  padding: 0 15px;

  .slick-list > div {
    margin-left: 0;
  }

  ${respondTo.md`
    padding: 0`}
`;

const Arrow = styled.div`
  position: absolute;
  right: 0;
  top: -50px;

  span {
    cursor: pointer;
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 4px;
    background-color: ${(props) => props.theme.primary};
    text-align: center;
  }

  .prev {
    position: relative;
    right: 50px;
  }
  .prev:before {
    content: "<";
    color: #fff;
    font-size: 24px;
  }
  .next {
    position: relative;
    right: 0;
  }
  .next:before {
    content: ">";
    color: #fff;
    font-size: 24px;
  }
`;

const Award = styled.div`
  padding: 0 10px;
  outline: none;

  .content {
    background-color: #fff;
    box-shadow: 0 3px 6px #63666919;
    border-radius: 20px;

    .aw-image {
      padding: 20px 0;
      display: flex;
      justify-content: center;

      img {
        max-width: 90%;
        max-height: 170px;
      }
    }

    .aw-content {
      padding: 15px;

      strong {
        text-transform: uppercase;
      }

      span {
        display: block;
        color: ${(props) => props.theme.primary};
        margin: 5px 0;
      }

      small {
        color: ${(props) => props.theme.text.primary};
      }
    }
  }
`;

const AwardsList = (props) => {
  const prizeList = useSelector((state) => state.promo.promoinfo.prizes);

  const options = {
    dots: false,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    swipeToSlide: true,
    prevArrow: (
      <Arrow>
        <span className="prev"></span>
      </Arrow>
    ),
    nextArrow: (
      <Arrow>
        <span className="next"></span>
      </Arrow>
    ),
    responsive: [
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="container">
      <AwTitle>Premi in palio</AwTitle>
      <AwardContent>
        {prizeList && (
          <Slider {...options}>
            {prizeList.map((prize, i) => (
              <Award key={i}>
                <div className="content">
                  <div className="aw-image">
                    <img src={prize.image} alt="" />
                  </div>
                  <div className="aw-content">
                    <span>{prize.title}</span>
                    <small>
                      dal {prize.start} al {prize.end}
                    </small>
                  </div>
                </div>
              </Award>
            ))}
          </Slider>
        )}
      </AwardContent>
    </div>
  );
};

export default AwardsList;
