import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;
    font-size: 100%;
    height: 100%;
  }
  body {
    font-family: ${(props) => props.font};
    font-size: 100%;
    height: 100%;
    background-color: #E4EAEF;
  }
  * {
    margin: 0;
    padding: 0;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  a {
    text-decoration: none;
  }
  .container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
  }
  .slick-disabled {
    opacity: 0.5;
  }
  #root {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export default GlobalStyle;
