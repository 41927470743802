import React from 'react';

import SigninForm from '../../components/SigninForm';
import AuthTemplate from '../../templates/AuthTemplate';

import { useTranslation } from 'react-i18next';

const Login = props => {
  const { logo } = props;

  const { from } = props.location.state || { from: '/' };
  const [t] = useTranslation();
  return (
    <AuthTemplate logo={logo} titlepage={t('auth.signinlabel')}>
      <SigninForm whereToGo={from} />

    </AuthTemplate>
  );
};

export default Login;
