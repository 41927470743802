import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { respondTo } from "../../theme/mixin";

const LogoutContainer = styled.div`
  font-size: 24px;
  text-transform: uppercase;
  padding: 13px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: ${(props) => props.theme.text.primary};

  ${respondTo.sm`
  font-size: 18px;`}
`;

const Logout = (props) => {
  const { clicked, label } = props;

  return (
    <LogoutContainer onClick={clicked} {...props}>
      {label}
      {isMobile && (
        <img src={process.env.PUBLIC_URL + "/icons/logout-icon.svg"} alt="" />
      )}
    </LogoutContainer>
  );
};

Logout.propTypes = {
  clicked: PropTypes.func.isRequired,
  children: PropTypes.any,
  label: PropTypes.string,
};

export default Logout;
