import React from 'react';

import AuthTemplate from '../../templates/AuthTemplate';

import { useTranslation } from 'react-i18next';
import RecoveryPasswordForm from '../../components/RecoveryPasswordForm';

const RecoveryPassword = props => {
  const { logo } = props;

  const [t] = useTranslation();
  return (
    <AuthTemplate logo={logo} titlepage={t('auth.recoverypswlabel')}>
      <RecoveryPasswordForm />
    </AuthTemplate>
  );
};

export default RecoveryPassword;
