import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "../../Ui";
import ErrorInModal from "../../ErrorInModal";
import { getElement } from "../../../utils/utils";

const Dynamic = (props) => {
	const [showError, setShowError] = useState(false);
	const {
		g: { id },
		action: { getDynamic, cleanError, data },
		errorInPage,
		resultData,
		e,
		slug,
		section,
		sid,
	} = props;

	useEffect(() => {
		let mounted = true;
		if (mounted) {
			getDynamic(id, slug, section, resultData, sid);
		}
		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (e) {
			setShowError(true);
		}
	}, [e]);

	const closeModalError = () => {
		setShowError(!showError);
		cleanError(id);
	};

	return (
		<React.Fragment>
			{showError ? (
				!errorInPage ? (
					<Modal show={showError} close={closeModalError}>
						<ErrorInModal error={e} />
					</Modal>
				) : null
			) : null}
		</React.Fragment>
	);
};

Dynamic.propTypes = {
	id: PropTypes.number,
	getDynamic: PropTypes.func,
	error: PropTypes.number,
	cleanError: PropTypes.func,
};

export default React.memo(Dynamic);