import React, { useState } from "react";
import { CustomContainer } from "../../components/Ui";
import CardCoupon from "../../components/CardCoupon";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";
import SinglePartner from "../SinglePartner";
import { respondTo } from "../../theme/mixin";
import { isMobile } from "react-device-detect";

const AreaPartner = () => {
  const partnerList = useSelector((store) => store.partner.partnerList);

  const PartnerContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    padding: 0 15px;

    ${respondTo.md`
      margin: 60px -10px 0 -10px;
    `}

    .single-partner {
      width: 50%;
      margin-top: 10px;
      padding: 0 5px;
      cursor: pointer;

      ${respondTo.sm`
        width: 50%;
        padding: 0 10px;
        margin-bottom: 15px;
      `}

      ${respondTo.md`
        width: 25%;
      `}

      .sp-header {
        overflow: hidden;
        border-radius: 20px 20px 0 0;
        height: 120px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .sp-body {
        position: relative;
        background-color: #fff;
        box-shadow: 0 3px 6px #63666919;
        border-radius: 0 0 20px 20px;
        padding-top: 50px;
        padding-bottom: 15px;

        strong {
          text-align: center;
          display: block;
          font-size: 14px;
          color: $primary;
          text-transform: uppercase;
        }

        .sp-brand {
          display: flex;
          align-items: center;
          position: absolute;
          padding: 0 5px;
          top: -40px;
          left: 50%;
          margin-left: -40px;
          width: 80px;
          height: 80px;
          background-color: #fff;
          border-radius: 50%;
          box-shadow: 0 3px 6px #00000029;

          img {
            width: 100%;
          }
        }

        .sp-value {
          margin-top: 15px;
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            font-size: 12px;
            font-weight: 700;
            margin-left: 7px;
            color: #6366697f;
          }
        }

        .sp-cta {
          position: absolute;
          bottom: 7px;
          right: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: $primary;
          box-shadow: 0 3px 6px rgba($primary, 0.7);
        }
      }
    }
  `;

  const [selectedPartner, setSelectedPartner] = useState(null);

  return (
    <>
      {selectedPartner || selectedPartner === 0 ? (
        <SinglePartner
          closed={() => setSelectedPartner(null)}
          partner={partnerList[selectedPartner]}
        />
      ) : (
        <CustomContainer>
          <div className="container">
            {isMobile && <CardCoupon margintop={20} />}
            <PartnerContainer>
              {partnerList.map((item, index) => (
                <div
                  className="single-partner"
                  key={index}
                  onClick={() => setSelectedPartner(index)}
                >
                  <div className="sp-header">
                    <img src={item.main_image} alt="" />
                  </div>
                  <div className="sp-body">
                    <div className="sp-brand">
                      <img src={item.logo} alt="" />
                    </div>
                    <strong>{item.title}</strong>
                    <div className="sp-value">
                      <img
                        src={process.env.PUBLIC_URL + "/icons/symbol-gray.svg"}
                        alt=""
                      />
                      <span>{item.value} €</span>
                    </div>
                    <Link to="/area-partner/" className="sp-cta">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/icons/chevron-right.svg"
                        }
                        alt=""
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </PartnerContainer>
          </div>
        </CustomContainer>
      )}
    </>
  );
};

export default AreaPartner;
