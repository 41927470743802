import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import GlobalStyle from "./components/GlobalStyle";
import "./i18n";

import { Loader } from "./components/Ui";

ReactDOM.render(
  <Provider store={store}>
    <GlobalStyle />
    <Suspense fallback={<Loader initial show={true} />}>
      <App />
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
