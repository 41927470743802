import React, { useState, useEffect } from "react";
import CardElement from "./CardElement";
import { getSlug } from "../../../../utils/utils";
import Dynamic from "../../Dynamics/Dynamic";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GameWrapper = styled.div`
  padding: 40px 15px;
  max-width: 450px;

  h3 {
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: #fff;
  }

  strong {
    color: #fff;
    margin-bottom: 30px;
    display: block;
  }

  span {
    text-align: center;
    display: block;
    font-size: 22px;
    color: #fff;
  }

  span.subtitle {
    margin-bottom: 35px;
  }

  span.extra {
    margin-top: 30px;
  }
`;

const GameContent = styled.div``;

const Card = (props) => {
  const {
    onShowResult,
    typeInteraction,
    g: {
      id,
      i18l,
      interaction,
      preinteraction,
      log: { sid },
    },
    action: { data: dataAction, loading },
  } = props;

  const [finalCards, setFinalCards] = useState([]);
  const [action, setAction] = useState(false);

  let rules = JSON.parse(interaction.data);

  if (typeInteraction === "preinteraction") {
    rules = JSON.parse(preinteraction.data);
  }

  let slug = getSlug();

  const {
    settings: {
      numberofcards,
      aspect: { brand, bggamecolor },
    },
  } = rules;

  useEffect(() => {
    let data = [];
    for (let i = 0; i < numberofcards; i++) {
      data.push({
        id: i,
        open: false,
        brand,
      });
    }
    setFinalCards(data);
  }, []);

  useEffect(() => {
    if (dataAction.length > 0) {
      setTimeout(() => {
        onShowResult();
      }, 200);
    }
  }, [dataAction]);

  const clickCardHandler = (index) => {
    finalCards[index].open = true;
    setAction(true);
  };

  return (
    <React.Fragment>
      <Container bggame={bggamecolor}>
        <GameWrapper>
          <h3>{i18l.info.title}</h3>
          <span className="subtitle">{i18l.info.content}</span>
          <GameContent>
            {finalCards.map((card, index) => {
              return (
                <CardElement
                  image={brand}
                  key={card.id}
                  index={index}
                  brand={card.brand}
                  isOpen={card.open}
                  result={dataAction}
                  idGame={id}
                  clicked={() => clickCardHandler(index)}
                />
              );
            })}
          </GameContent>
          <span className="extra">{i18l.info.extra}</span>
        </GameWrapper>
      </Container>
      {action ? (
        <Dynamic
          id={id}
          sid={sid}
          slug={slug}
          section={typeInteraction}
          action={props.action}
          {...props}
        />
      ) : null}
    </React.Fragment>
  );
};

Card.propTypes = {};

export default Card;
