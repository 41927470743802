import React from 'react';
import AuthTemplate from '../../templates/AuthTemplate';
import { useTranslation } from 'react-i18next';
import RegisterForm from '../../components/RegisterForm';

const Register = props => {
  const { logo } = props;
  const [t] = useTranslation();
  return (
    <AuthTemplate logo={logo} titlepage={t('auth.registerlabel')}>
      <RegisterForm />
    </AuthTemplate>
  );
};

export default Register;
