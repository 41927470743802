import React, { useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { useTransition, useChain, animated } from "react-spring";

const Overlay = styled(animated.div)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  justify-content: center;
  z-index: 99;
  /* overflow: scroll; */
`;

const Content = styled(animated.div)`
  width: ${props => {
    switch (props.size) {
      case "lg":
        return "800";
      default:
        return "500";
    }
  }}px;
  max-width: 90%;
  background-color: ${props =>
    props.theme.modal ? props.theme.modal : "#fff"};
  /* border-radius: 8px; */
  position: absolute;
  padding: 30px;
  top: 50px;
  z-index: 998;
  clip-path: ${props =>
    props.theme.isrect
      ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
      : "polygon(0 0, 100% 0, 100% 100%, 0 97%)"};
`;

const ModalContainer = styled.div`
  position: relative;
`;

const CloseModal = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
  background-color: ${props =>
    props.theme.primary ? props.theme.primary : "#000"};
  color: #fff;
  width: 25px;
  height: 25px;
  text-align: center;
  line-height: 25px;
  z-index: 999;
  cursor: pointer;
  clip-path: ${props =>
    props.theme.isrect
      ? "polygon(0 0, 100% 0, 100% 100%, 0 100%)"
      : "polygon(0 0, 100% 0, 100% 100%, 0 95%)"};
  &:after {
    content: "X";
  }
`;

const Modal = props => {
  const { close, children, show } = props;

  const overlayTransitionRef = useRef();
  const overlayTransition = useTransition(show, null, {
    ref: overlayTransitionRef,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 }
  });

  const modalTransitionRef = useRef();
  const modalTransition = useTransition(show, null, {
    ref: modalTransitionRef,
    unique: true,
    trail: 100,
    from: { opacity: 0, transform: "translateY(-40px)" },
    enter: { opacity: 1, transform: "translateY(0)" },
    leave: { opacity: 0, transform: "translateY(-40px)" }
  });

  useChain(
    show
      ? [overlayTransitionRef, modalTransitionRef]
      : [modalTransitionRef, overlayTransitionRef],
    [0, show ? 0.1 : 0.6]
  );

  return overlayTransition.map(({ item, key, props }) =>
    item ? (
      <Overlay key={key} style={props}>
        {modalTransition.map(({ item, key, props }) =>
          item ? (
            <Content key={key} style={props}>
              <CloseModal onClick={close} />
              <ModalContainer>{children}</ModalContainer>
            </Content>
          ) : null
        )}
      </Overlay>
    ) : null
  );
};

Modal.propTypes = {
  close: PropTypes.func,
  children: PropTypes.any,
  show: PropTypes.bool
};

export default Modal;
