import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { resetPasswordForm } from "../../formsConfig/formsConfig";
import { getOneTimeToken } from "../../utils/utils";
import useForm from "../../hooks/useForm";
import { useTranslation } from "react-i18next";
import { Button, Input, Loader, Modal, Typography, SmallLink } from "../Ui";
import ErrorInModal from "../ErrorInModal";
import styled from "styled-components";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreators from "../../store/actions";

const AccessWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 3px 6px #63666919;
  border-radius: 20px;
  padding: 20px;

  input {
    background-color: #63666919;
    margin-bottom: 20px;
  }

  input::placeholder {
    color: #6366697f;
  }
`;

const ChangeConfirm = styled.p`
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
  color: ${(props) => props.theme.secondary};
`;

const LoaderContainer = styled.div`
  min-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ResetPasswordForm = (props) => {
  const inputRef = useRef();

  const {
    resetPassword,
    reset,
    errorReset,
    loadingReset,
    clearError,
    changePassword,
    loadingChangePsw,
    changePsw,
    errorChangePsw,
    clearChangeError,
  } = props;

  const [showModal, setShowModal] = useState(true);
  const [showErrorChangeModal, setShowErrorChangeModal] = useState(false);

  const [t] = useTranslation();
  const resetForm = resetPasswordForm(
    t("auth.insertnewpsw"),
    t("auth.confirmnewpsw"),
    t("auth.pswerror"),
    t("auth.pswconfirmerror"),
    inputRef
  );

  useEffect(() => {
    resetPassword(getOneTimeToken());
  }, []);

  useEffect(() => {
    if (reset) {
      inputRef.current.focus();
    }
  }, [reset]);

  useEffect(() => {
    if (errorChangePsw !== null) {
      setShowErrorChangeModal(true);
    }
  }, [errorChangePsw]);

  const resetFormSubmit = () => {
    changePassword(
      formData.password.value,
      formData.confirmpassword.value,
      getOneTimeToken()
    );
  };

  const modalHandler = () => {
    setShowModal(false);
    clearError();
  };

  const modalChangeErrorHandler = () => {
    setShowErrorChangeModal(false);
    clearChangeError();
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
  } = useForm(resetFormSubmit, resetForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
      />
    );
  });

  return (
    <div>
      {reset ? (
        changePsw ? (
          <>
            <ChangeConfirm>{t("auth.changepswok")}</ChangeConfirm>
            <Link to="/auth/login">
              <Button upper fullwidth>
                {t("auth.signinlabel")}
              </Button>
            </Link>
          </>
        ) : (
          <AccessWrapper>
            <form onSubmit={formSubmitHandler}>
              {inputs}
              <Button upper type="submit" fullwidth>
                {loadingChangePsw ? (
                  <Loader inBtn show={true} />
                ) : (
                  t("auth.confirmlabel")
                )}
              </Button>
            </form>
          </AccessWrapper>
        )
      ) : loadingReset ? (
        <LoaderContainer>
          <Loader show={loadingReset} />
        </LoaderContainer>
      ) : (
        <>
          <Modal show={showModal} close={modalHandler}>
            <ErrorInModal error={errorReset} />
          </Modal>
          <Link to="/auth/recovery-password">
            <Button fullwidth upper>
              {t("auth.recoverypswlabel")}
            </Button>
          </Link>
        </>
      )}
      <Modal show={showErrorChangeModal} close={modalChangeErrorHandler}>
        <ErrorInModal error={errorChangePsw} />
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    reset: state.auth.reset,
    errorReset: state.auth.errorReset,
    loadingReset: state.auth.loadingReset,
    loadingChangePsw: state.auth.loadingChangePsw,
    changePsw: state.auth.changePsw,
    errorChangePsw: state.auth.errorChangePsw,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetPassword: (onetimetoken) =>
      dispatch(actionCreators.resetPassword(onetimetoken)),
    clearError: () => dispatch(actionCreators.resetPasswordClearError()),
    changePassword: (psw, confirmpsw, onetimetoken) =>
      dispatch(actionCreators.changePassword(psw, confirmpsw, onetimetoken)),
    clearChangeError: () => dispatch(actionCreators.changePasswordClearError()),
  };
};

ResetPasswordForm.propTypes = {
  resetPassword: PropTypes.func,
  reset: PropTypes.bool,
  errorReset: PropTypes.number,
  clearError: PropTypes.func,
  changePassword: PropTypes.func,
  loadingChangePsw: PropTypes.bool,
  changePsw: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm);
