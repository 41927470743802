import React, { useState } from "react";
import { Input, Button, CustomContainer } from "../../components/Ui";
import { contactForm } from "../../formsConfig/formsConfig";
import styled from "styled-components";
import useForm from "../../hooks/useForm";
import { respondTo } from "../../theme/mixin";
import customaxios from "../../config/axios-refresh-token";
import ModalMessage from "../../components/ModalMessage";

const ContactWrapper = styled.div`
  margin-top: 20px;
  padding: 0 15px;

  ${respondTo.sm`
    margin: 40px auto 0 auto;
    width: 100%;
    max-width: 750px;
	`}

  .contactContent {
    padding: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 3px 6px #63666919;

    h1 {
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
`;

const Contact = () => {
  const [modal, setModal] = useState(false);

  const submitHandler = () => {
    customaxios
      .post(
        "/app/contactus",
        { topic: formData.topic.value, message: formData.message.value },
        {
          headers: {
            "X-Custom-Header": { alias: "insiemepervincere.com", lang: "it" },
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          setModal(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
  } = useForm(submitHandler, contactForm);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        inModal={inp.inModal}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
      />
    );
  });

  return (
    <>
      <CustomContainer>
        <div className="container">
          <ContactWrapper>
            <div className="contactContent">
              <form onSubmit={formSubmitHandler}>
                <h1>Scrivici</h1>
                {inputs}
                <Button upper fullwidth>
                  Invia richiesta
                </Button>
              </form>
            </div>
          </ContactWrapper>
        </div>
      </CustomContainer>
      {modal && (
        <ModalMessage title="Messaggio inviato" closed={() => setModal(false)}>
          <p>Grazie, il tuo messaggio è stato inviato con successo.</p>
        </ModalMessage>
      )}
    </>
  );
};

export default Contact;
