import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const RegisterFormSuccess = () => {
  const [t] = useTranslation();

  const RegisterForm = styled.div`
    p {
        text-align: center;
        display: block;
        margin-top: 30px;
        line-height: 1.5;
    }
  `;

  return (
    <RegisterForm>
      <p>{t("auth.registerformsuccess")}</p>
    </RegisterForm>
  );
};

export default RegisterFormSuccess;
