import React from "react";
import { animated, useTransition } from "react-spring";

const Fade = (props) => {
	const { children, show } = props;

	const transitions = useTransition(show, null, {
		from: { opacity: 0, transform: "translate3d(0,8px,0)" },
		enter: { opacity: 1, transform: "translate3d(0,0px,0)" },
		leave: { opacity: 0, transform: "translate3d(0,8px,0)" },
	});

	return transitions.map(
		({ item, key, props }) =>
			item && (
				<animated.div key={key} style={props}>
					{children}
				</animated.div>
			)
	);
};

export default Fade;
