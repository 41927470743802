import React from 'react';
import AuthTemplate from '../../templates/AuthTemplate';
import { useTranslation } from 'react-i18next';
import ResetPasswordForm from '../../components/ResetPasswordForm';

const ResetPassword = props => {
  const { logo } = props;

  const [t] = useTranslation();
  return (
    <AuthTemplate logo={logo} titlepage={t('auth.resetpswlabel')}>
      <ResetPasswordForm />
    </AuthTemplate>
  );
};

export default ResetPassword;
