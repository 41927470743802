import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux';
import * as actionCreators from '../../store/actions';
import { Loader } from '../../components/Ui';

const Oauth = props => {

    const [redirect, setRedirect] = useState(false);

    const { 
        history: { location : {search} },
        match: { params: {connect} },
        oauthSendToken,
        isLogged
    } = props;

    let code = search.split("=")[1];

    useEffect(() => {
        if(isLogged) {
            if(localStorage.getItem('lasturl')) {
                window.location.href = localStorage.getItem('lasturl')
            } else {
                setRedirect(true);
            }
        } else {
            oauthSendToken(code, connect);
        }
    }, [isLogged])


   

    return (
        <div>
            { (redirect) ? <Redirect to="/" /> : <Loader show={!redirect} initial /> }
        </div>
    )
}

Oauth.propTypes = {
    search: PropTypes.string
}

const mapStateToProps = state => {
    return {
        isLogged: state.auth.token
    }
}

const mapDispatchToProps = dispatch => {
    return {
        oauthSendToken: (code, connect) => dispatch(actionCreators.oauthSendToken(code, connect))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Oauth);
