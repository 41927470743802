import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { NavLink } from "react-router-dom";
import ErrorInModal from "../../../ErrorInModal";
import { Modal } from "../../../Ui";

const NavItem = styled.li`
  display: inline-block;
  margin-right: 20px;

  &:last-child {
    margin-right: 0;
  }

  a {
    height: 27px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    color: ${(props) => props.theme.text.primary};
    font-size: 18px;
    border: 1px solid transparent;
    border-radius: 50px;

    &.active,
    &:hover {
      color: ${(props) => props.theme.primary};
      border-color: ${(props) => props.theme.primary};
    }
  }
`;

const LinkElem = styled(NavLink)``;

const NavigationItem = (props) => {
  const { link, children } = props;

  const [isWedModal, setIsWedModal] = useState(false);

  const closeWedModal = () => {
    setIsWedModal(false);
  };

  useEffect(() => {
    return () => {
      setIsWedModal(false);
    };
  }, []);

  return (
    <NavItem>
      <LinkElem exact to={link} {...props}>
        {children}
      </LinkElem>
      {isWedModal && (
        <Modal show={isWedModal} close={closeWedModal}>
          <ErrorInModal error={"Attendi il mercoledì per provare a vincere!"} />
        </Modal>
      )}
    </NavItem>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

export default NavigationItem;
