import React from 'react';
import Helmet from 'react-helmet';


const Seo = (props) => {

    const { title, description, keywords } = props;

    return (
        <Helmet>
            <meta name="description" content={`${description}`} />
            <meta name="keywords" content={`${keywords}`} />
            <title>{`${title}`}</title>
        </Helmet>
    )
}

export default Seo;