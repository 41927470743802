import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { Loader, CustomContainer, Button, Input } from "../../components/Ui";
import { useLastLocation } from "react-router-last-location";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";
import useForm from "../../hooks/useForm";
import { profileform } from "../../formsConfig/formsConfig";
import ModalMessage from "../../components/ModalMessage";

const ProfileContainer = styled.div`
  margin-top: 50px;
  padding: 0 20px;

  ${respondTo.sm`padding: 0;`}
`;

const UploadStep = styled.ul`
  list-style-type: none;
  background-color: #fff;
  border-radius: 20px;

  ${respondTo.sm`
    display: flex;
    align-items: flex-start;
		border-radius: 0;
		background-color: transparent;
	`}

  & li {
    padding: 20px;
    border-bottom: 1px solid #c5cbd1;

    ${respondTo.sm`
			width: 50%;
			margin: 0 15px;
			background-color: #fff;
			border-radius: 20px;
			border: none;
			box-shadow: 0 3px 6px #63666919;
    `}

    ${respondTo.md`
			width: calc(33.333% - 15px);
		`}

    & input[type="number"] {
      background-color: ${(props) => props.theme.input.bg};
    }

    label {
      align-items: flex-start;
      color: ${(props) => props.theme.secondary};
    }

    .buttons {
      display: flex;
    }
  }

  & li:last-child {
    border-bottom: none;
  }
`;

const TextP = styled.h3`
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const UserCode = styled.div`
  margin-bottom: 20px;
  padding: 16px;
  background: #fafafa;
`;

const UserCodeP = styled.p`
  margin-bottom: 8px;
`;

const Profile = (props) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const lastLocation = useLastLocation();
  const [t] = useTranslation();
  let pathname = null;

  if (lastLocation) {
    pathname = lastLocation.pathname;
  }
  const {
    editUser,
    closeModalEdit,
    getUserIsLogged,
    userLogged,
    userInfo,
    updateModal,
    loading,
    logout,
    codes,
    token,
    toggleSidebar,
  } = props;

  const convertDate = (inputFormat) => {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  };

  const reconvertDate = (inputFormat) => {
    return inputFormat.split("/").reverse().join("-");
  };

  const profileForm = profileform(
    userInfo && userInfo.firstname,
    userInfo && userInfo.lastname,
    userInfo && userInfo.email,
    userInfo && convertDate(userInfo.dob)
  );

  const updateProfile = () => {
    let editData = {
      firstname: formData.firstname.value,
      lastname: formData.lastname.value,
      email: formData.email.value,
      dob: reconvertDate(formData.dob.valuetosend),
    };
    editUser(editData);
  };

  let datePickerNames = [];

  for (var prop in profileForm) {
    if (profileForm[prop].elementType === "datepicker") {
      datePickerNames.push(profileForm[prop].elementConfig.name);
    }
  }

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    startDate,
    datePickerHandler,
  } = useForm(updateProfile, profileForm, datePickerNames);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }
  let inputs = inputArr.map((inp, indx) => {
    return (
      <Input
        inModal={inp.inModal}
        key={indx}
        value={inp.value || ""}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        lang="it"
        istouched={inp.validation.touched}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        {...inp}
      />
    );
  });

  useEffect(() => {
    getUserIsLogged();
    return () => {
      closeModalEdit();
    };
  }, []);

  useEffect(() => {
    setShowSidebar(true);
  }, [userLogged]);

  let userCodes = null;
  if (codes) {
    userCodes = codes.map((code) => (
      <UserCode key={code.code}>
        <UserCodeP>Codice: {code.code}</UserCodeP>
        <UserCodeP>Promo: {code.promo}</UserCodeP>
        <UserCodeP>Gioco: {code.game}</UserCodeP>
        <UserCodeP>Giocato in data: {code.date}</UserCodeP>
      </UserCode>
    ));
  }

  return (
    <CustomContainer>
      <div className="container">
        <ProfileContainer>
          {!loading && userInfo ? (
            <form onSubmit={formSubmitHandler}>
              <UploadStep>
                <li>
                  <TextP>I tuoi dati</TextP>
                  {inputs}
                </li>
                <li>
                  <Button upper fullwidth>
                    Modifica
                  </Button>
                </li>
              </UploadStep>
            </form>
          ) : (
            <Loader show={loading} />
          )}
        </ProfileContainer>
      </div>

      {updateModal && (
        <ModalMessage
          title="Profilo aggiornato"
          closed={() => closeModalEdit()}
        >
          Profilo aggiornato correttamente
        </ModalMessage>
      )}
    </CustomContainer>
  );
};

const mapStateToProps = (state) => {
  return {
    userLogged: state.user.userLogged,
    userInfo: state.user.user.userinfo,
    loading: state.user.loading,
    codes: state.user.user.codes,
    updateModal: state.user.updateModal,
    updateError: state.user.user.updateError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeModalEdit: () => dispatch(actionCreators.closeModalEdit()),
    editUser: (data) => dispatch(actionCreators.editUser(data)),
    getUserIsLogged: () => dispatch(actionCreators.getUserIsLogged()),
    logout: () => dispatch(actionCreators.logout()),
    toggleSidebar: () => dispatch(actionCreators.toggleSidebar()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
