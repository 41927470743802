import React, { useState, useEffect } from "react";
import { setCookie, getCookie } from "../../utils/utils";
import { Button } from "../Ui";
import Fade from "../Fade";
import styled from "styled-components";
import { respondTo } from "../../theme/mixin";

const Container = styled.div`
  position: fixed;
  bottom: 30px;
  z-index: 9999999;
  ${respondTo.sm`
        right: 30px;
    `}
`;

const Banner = styled.div`
  padding: 16px;
  border-radius: 8px;
  width: 90%;
  margin: auto;
  max-width: 600px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
`;

const GDPR = (props) => {
  const {
    content: {
      gdpr: { content, extra },
    },
  } = props;

  const [showBanner, setShowBanner] = useState(false);

  const setCookieHandler = () => {
    setCookie("gdpr", true, 30);
    setShowBanner(false);
  };

  useEffect(() => {
    setTimeout(() => {
      if (!getCookie("gdpr")) {
        setShowBanner(true);
      }
    }, 1000);
  }, []);

  return (
    showBanner && (
      <Container>
        <Fade show={showBanner}>
          <Banner>
            <Text>{content}</Text>
            <Button onClick={setCookieHandler}>{extra}</Button>
          </Banner>
        </Fade>
      </Container>
    )
  );
};

export default GDPR;
