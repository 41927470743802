import styled from "styled-components";

const InputField = styled.input`
  outline: none;
  width: 100%;
  height: 50px;
  background-color: ${(props) => props.theme.input.bg};
  border: 1px solid
    ${(props) =>
      (!props.isvalid && props.istouched && props.firstSubmit) ||
      (!props.isvalid && props.firstSubmit)
        ? props.theme.input.error
        : props.theme.input.bg};
  border-radius: 15px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-size: 18px;
  color: ${(props) => props.theme.primary};
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
`;

export default InputField;
