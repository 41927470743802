import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logodefault from "../../../assets/images/logo-default.png";

const LogoContainer = styled.div`
  max-width: 150px;
  margin: ${(props) => (props.center ? "auto" : "")};
`;

const Logo = (props) => {
  const { nolink, logo } = props;

  let logoContent = null;
  let logoimg = null;
  if (!logo) {
    logoimg = logodefault;
  } else {
    logoimg = logo;
  }

  if (nolink) {
    logoContent = <img src={logoimg} alt="" />;
  } else {
    logoContent = (
      <Link to="/">
        <img src={logoimg} alt="" />
      </Link>
    );
  }

  return <LogoContainer {...props}>{logoContent}</LogoContainer>;
};

Logo.defaultProps = {
  logo: logodefault,
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
