import React from "react";
import styled from "styled-components";
import { getElement } from "../../../../../utils/utils";

const CardWrapper = styled.div`
  position: relative;
`;

const GameImage = styled.div`
  cursor: pointer;
  width: 280px;
  height: 280px;
  margin: 0 auto;
  background-image: url(${(props) => props.image});
  background-size: cover;
`;

const CardElement = (props) => {
  const { clicked } = props;

  return (
    <>
      <CardWrapper>
        <GameImage {...props} onClick={clicked}></GameImage>
      </CardWrapper>
    </>
  );
};

export default CardElement;
