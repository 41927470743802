import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Logout from "../components/Logout";
import LoginBtn from "../components/LoginBtn";
import { SwitchLang } from "../components/Ui";
import styled from "styled-components";
import { useSelector } from "react-redux";

const useNavigation = (
  isLogged,
  logout,
  langs,
  bootApp,
  ownlogin,
  oauths,
  oauthinfo,
  oauth_profile_url,
  handleClick
) => {
  const [t] = useTranslation();

  const promoId = useSelector((state) => state.promo.promoinfo.id);

  const [promoN, setPromoN] = useState();

  useEffect(() => {
    if (promoId) {
      setPromoN(promoId);
    }
  }, [promoId]);

  const [navMobile, setNavMobile] = useState([
    {
      path: "/",
      label: t("app.allthepromo"),
      key: "a",
      type: "link",
    },
  ]);

  const date = new Date();
  const currentDay = date.getDay();

  useEffect(() => {
    if (isLogged) {
      let profile = null;
      let cta = null;
      if (oauth_profile_url) {
        profile = oauth_profile_url;
        cta = "ext";
      } else {
        profile = "/user/profile";
        cta = "int";
      }
      setNavMobile([
        {
          path: "/",
          label: t("header.homepage"),
          key: "a",
          type: "link",
        },
        {
          path: `/promo/game/${promoN}`,
          label: t("header.play"),
          key: "b",
          type: "link",
          isWed: false,
        },
        {
          path: "/area-partner",
          label: t("header.areapartner"),
          key: "ap",
          type: "link",
        },
        {
          path: profile,
          label: t("header.profile"),
          key: "c",
          type: "link",
        },
        {
          path: "/rules",
          label: t("header.how"),
          key: "d",
          type: "link",
        },
        {
          path: "/contact",
          label: t("header.contact"),
          key: "e",
          type: "link",
        },
        {
          path: "/user/awards",
          label: (
            <Logout
              key="ppp"
              label={t("auth.logoutlabel")}
              clicked={handleClick}
            />
          ),
          key: "f",
          type: "nolink",
        },
        {
          path: "/user/awards",
          label:
            langs.length > 1 ? (
              <SwitchLang bootApp={bootApp} langs={langs} insidebar />
            ) : null,
          key: "i",
          type: "nolink",
        },
      ]);
    } else {
      setNavMobile([
        //...navMobile,
        {
          path: "/",
          label: t("header.homepage"),
          key: "a",
          type: "link",
        },
        {
          path: "/user/awards",
          label: (
            <LoginBtn
              label={t("auth.signinlabel")}
              ownlogin={ownlogin}
              oauths={oauths}
              oauthinfo={oauthinfo}
            />
          ),
          key: "g",
          type: "nolink",
        },
        {
          path: "/user/awards",
          label:
            langs.length > 1 ? (
              <SwitchLang bootApp={bootApp} langs={langs} insidebar />
            ) : null,
          key: "h",
          type: "nolink",
        },
      ]);
    }
  }, [isLogged, promoN]);

  return {
    navMobile,
  };
};

export default useNavigation;
