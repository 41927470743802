import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Section, Loader, CustomContainer } from "../../components/Ui";
import Seo from "../../components/Seo";
import Games from "../../components/Games";
import { getSlug } from "../../utils/utils";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as actionCreators from "../../store/actions";
import { Redirect } from "react-router-dom";

const Game = (props) => {
  const {
    appConfig: {
      settings: { multipromo },
    },
    userWin,
    lang,
    isLogged,
    getGame,
    game,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    errorSendGameResult,
    loadingGetCodeAfterResult,
    loadinggame,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
    match: {
      params: { id },
    },
  } = props;

  let sendGameObj = {
    getGame,
    gameRetry,
    setGameRetry,
    sendGameResult,
    loadingSendGameResult,
    loadingGetCodeAfterResult,
    errorSendGameResult,
    getCodeAfterGame,
    loadingGetCodeAfterGame,
    errorGetCodeAfterGame,
  };

  let slug = "play";
  let seo = null;

  if (multipromo) {
    slug = getSlug();
  }

  if (game.length > 0) {
    const {
      i18l: {
        seo: { title, description, keywords },
      },
    } = game[0];
    seo = <Seo title={title} description={description} keywords={keywords} />;
  }

  useEffect(() => {
    getGame(
      id,
      slug,
      props.history.location.state ? props.history.location.state.cid : ""
    );
    window.scrollTo(0, 0);
  }, []);

  const date = new Date();
  const currentDay = date.getDay();


  return (
    <CustomContainer>
      {loadinggame ? (
        <Loader show={loadinggame} full />
      ) : (
        <React.Fragment>
          {seo}
          <Games
            games={game}
            isLogged={isLogged}
            sendGameObj={sendGameObj}
            lang={lang}
          />
        </React.Fragment>
      )}
    </CustomContainer>
  );
};

Game.propTypes = {};

const mapStateToProps = (state) => {
  return {
    userWin: state.user.user.has_pending_play,
    lang: state.app.config.lang,
    game: state.promo.game,
    loadinggame: state.promo.loadinggame,
    loadingSendGameResult: state.game.loadingSendGameResult,
    errorSendGameResult: state.game.errorSendGameResult,
    loadingGetCodeAfterGame: state.game.loadingGetCodeAfterGame,
    errorGetCodeAfterGame: state.game.errorGetCodeAfterGame,
    loadingGetCodeAfterResult: state.game.loadingGetCodeAfterResult,
    gameRetry: state.promo.gameRetry,
    appConfig: state.app.config,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGame: (id, slug, cid) => dispatch(actionCreators.getGame(id, slug, cid)),
    sendGameResult: (id, result) =>
      dispatch(actionCreators.sendGameResult(id, result)),
    getCodeAfterGame: (id, result, gc) =>
      dispatch(actionCreators.getCodeAfterGame(id, result, gc)),
    setGameRetry: () => dispatch(actionCreators.setGameRetry()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Game));
