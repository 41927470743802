import styled from 'styled-components';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import { darken } from 'polished';

const Button = styled.button`
  border-radius: ${props =>
    props.theme.btnRound ? '1.5625rem' : '0'}; /* 25px */
  border: 0;
  display: inline-block;
  text-align: center;
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  outline: none;
  color: ${props => props.theme.btnTextColor};
  padding: 0 1.5625rem; /* 25px */
  min-width: ${props =>
    props.small ? '10rem' : '10.625rem'}; /* 120px */ /* 170px */
  min-width: ${props =>
    props.fullwidth ? '100%' : ''}; /* 120px */ /* 170px */
  background-color: ${props =>
    props.bgcolor
      ? props.bgcolor
      : props.secondary
      ? props.theme.secondary
      : props.theme.primary};
  background-image: ${props => `url(${props.bgimage})`};
  background-repeat: no-repeat;
  background-position: 22px center;
  transition: all 0.3s;
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  text-transform: ${props => (props.upper ? 'uppercase' : 'none')};
  opacity: ${props => (props.disabled ? '.5' : '1')};
  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props =>
          props.bgcolor
            ? darken(0.1, props.bgcolor)
            : props.secondary
            ? darken(0.1, props.theme.secondary)
            : darken(0.1, props.theme.primary)};
      }
    `}
`;

Button.defaultProps = {
  color: '#000'
};

Button.propTypes = {
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  fullwidth: PropTypes.bool,
  secondary: PropTypes.bool,
  upper: PropTypes.bool
};

export default Button;
