import React from "react";
import styled from "styled-components";
import { Wrapper, Button, Typography } from "../../../Ui";

const ResultTitle = styled.p`
  margin-top: 30px;
  text-align: center;
  font-size: 38px;
  font-weight: 700;
  color: ${(props) => props.theme.primary};
`;

const ResultDynamic = (props) => {
  const {
    dynamicData: { title, content },
  } = props;

  return (
    <Wrapper>
      <ResultTitle>{title}</ResultTitle>
    </Wrapper>
  );
};

export default ResultDynamic;
