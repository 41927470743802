import React from "react";
import styled from "styled-components";

const Container = styled.div``;

const Title = styled.p`
  text-align: center;
  font-size: 24px;
  color: #42210b;
  text-transform: uppercase;
  margin-bottom: 15px;
`;

const Content = styled.p`
  text-align: center;
  margin-bottom: 15px;
  color: ${(props) => props.theme.secondary}
`;

const Prize = (props) => {
  const {
    prize: { title, content },
  } = props;

  return (
    <Container>
      <Title>{title}</Title>
      <Content>{content}</Content>
    </Container>
  );
};

export default Prize;
