import React, { useEffect } from 'react';
import { getAlias } from '../../utils/utils';
import * as actionCreators from '../../store/actions';
import { connect } from 'react-redux';

const Panel = (props) => {

    const { gotopanel } = props;

    const alias = getAlias();

    useEffect(() => {
        gotopanel(alias);
    }, []);

    return null;
}

const mapDispatchToProps = dispatch => {
    return {
        gotopanel: alias => dispatch(actionCreators.goToPanel(alias))
    }
}

export default connect(null, mapDispatchToProps)(Panel);