import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";

const FooterContainer = styled.footer`
  background-color: #f1f4f7;
  flex-shrink: 0;
`;

const MainBar = styled.div`
  padding: 20px;
  border-bottom: 2px solid ${(props) => props.theme.primary};

  .MainBarContent {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo-footer {
      max-width: 150px;
    }

    .contact-link {
      text-align: center;

      .contact-span {
        display: block;
        font-size: 10px;
        text-transform: uppercase;
        color: ${(props) => props.theme.primary};
      }
    }
  }
`;

const BottomBar = styled.div`
  text-align: center;
  padding: 10px 0;
  font-size: 10px;
  color: #636669;
`;

const Footer = (props) => {
  const { footercontent } = props;

  const content = footercontent.find((x) => x.name === "footer");
  const leftLogo = content
    ? content.contents.find((x) => x.name === "logo").img
    : null;
  const bottomText = content
    ? content.contents.find((x) => x.name === "text").i18l.content
    : null;
  const rightImage = content
    ? content.contents.find((x) => x.name === "imgContatti").img
    : null;
  const rightText = content
    ? content.contents.find((x) => x.name === "textContatti").i18l.content
    : null;

  return (
    <FooterContainer>
      <MainBar>
        <div className="container">
          <div className="MainBarContent">
            {leftLogo && <img src={leftLogo} alt="" />}
            <Link to="/contact" className="contact-link">
              <img src={rightImage} alt="" />
              <span className="contact-span">{parse(rightText)}</span>
            </Link>
          </div>
        </div>
      </MainBar>
      <BottomBar>{bottomText && parse(bottomText)}</BottomBar>
    </FooterContainer>
  );
};

Footer.propTypes = {
  footercontent: PropTypes.array,
};

export default Footer;
