import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Logo } from "../Ui";
import NavigationItems from "../Navigation/NavigationItems/NavigationItems";
import MenuLateral from "../MenuLateral";
import { respondTo } from "../../theme/mixin";
import { isBrowser } from "react-device-detect";

const HeaderSection = styled.header`
  border-top: 3px solid ${(props) => props.theme.primary};
  box-shadow: 0 3px 6px #63666919;
  background-color: #fff;
`;
const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  height: ${(props) => props.theme.header_mobile_height + "px"};
  ${respondTo.lg`
    padding: 0;
    height: ${(props) => props.theme.header_desktop_height + "px"};
  `}
`;

const NavContainer = styled.div`
  margin-left: 40px;
`;

const Header = (props) => {
  const { logo, isLogged, multipromo } = props;

  return (
    <HeaderSection>
      <div className="container">
        <HeaderContainer>
          {isBrowser && (
            <>
              <Logo logo={logo} />
              <NavContainer>
                <NavigationItems isLogged={isLogged} multipromo={multipromo} />
              </NavContainer>
            </>
          )}
          <MenuLateral {...props} />
        </HeaderContainer>
      </div>
    </HeaderSection>
  );
};

Header.propTypes = {
  toggleSidebar: PropTypes.func,
  logo: PropTypes.string,
  appInit: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
};

export default Header;
