export const signinform = (ref, inModal) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: "Email",
        ref,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: "Password",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal,
    },
  };
};

export const registrationform = (
  email,
  firstname,
  lastname,
  psw,
  pswrepeat,
  pswerror,
  pswconfirmerror
) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: `${email}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    dob: {
      elementType: "datepicker",
      elementConfig: {
        placeholder: "Data di nascita",
        name: "dob",
      },
      label: "",
      value: "",
      valuetosend: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: `${psw}`,
      },
      label: "",
      value: "",
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswerror}`,
      valid: false,
    },
    repeatpassword: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "repeatpassword",
        id: "repeatpassword",
        placeholder: `${pswrepeat}`,
      },
      label: "",
      value: "",
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: `${pswconfirmerror}`,
      valid: false,
    },
    firstname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstname",
        id: "firstname",
        placeholder: `${firstname}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    lastname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastname",
        id: "lastname",
        placeholder: `${lastname}`,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    gdpr_1: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "gdpr_1",
        id: "gdpr_1",
      },
      label:
        "<p>Dichiaro di aver letto e accettato il <a href='https://plugandplay.nextgroup.eu/storage/alg/regolamento.pdf' target='_blank'>regolamento.</a></p>",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    accettazione_regolamento: {
      elementType: "inputcheckbox",
      elementConfig: {
        type: "checkbox",
        placeholder: "",
        name: "accettazione_regolamento",
        id: "accettazione_regolamento",
      },
      label:
        "<p>Autorizzo al trattamento dei dati per le finalità indicate come obbligatorie nell’<a href='https://plugandplay.nextgroup.eu/storage/alg/privacy.pdf' target='_blank'>informativa privacy</a> connessa alla raccolta</p>",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const recoveryPasswordForm = (emailLabel, ref) => {
  return {
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: `${emailLabel}`,
        ref,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const resetPasswordForm = (
  newPswLabel,
  confirmNewPsw,
  pswerror,
  pswconfirmerror,
  ref
) => {
  return {
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "password",
        id: "password",
        placeholder: newPswLabel,
        ref,
      },
      label: "",
      value: "",
      validation: {
        toMatch: true,
        isPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: pswerror,
      valid: false,
    },
    confirmpassword: {
      elementType: "input",
      elementConfig: {
        type: "password",
        name: "confirmpassword",
        id: "confirmpassword",
        placeholder: confirmNewPsw,
      },
      label: "",
      value: "",
      validation: {
        isPassword: true,
        isConfirmPassword: true,
        required: true,
        touched: false,
      },
      errorMessage: pswconfirmerror,
      valid: false,
    },
  };
};

export const uploadReceiptForm = (dateLabel, numberLabel, amountLabel) => {
  return {
    receipt: {
      elementType: "inputfile",
      elementConfig: {
        type: "file",
        accept: "image/*,application/pdf",
        placeholder: "Carica la fattura",
        name: "receipt",
        id: "receipt",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
      inModal: true,
    },
    date: {
      elementType: "datepicker",
      elementConfig: {
        placeholder: dateLabel,
        name: "date",
      },
      label: "",
      value: "",
      valuetosend: "",
      validation: {
        required: true,
        touched: false,
        minDate: new Date("2020-07-15"),
        maxDate: new Date("2020-10-15"),
      },
      valid: false,
      title: "Dati fattura",
    },
    number: {
      elementType: "input",
      elementConfig: {
        type: "number",
        name: "number",
        id: "number",
        placeholder: numberLabel,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    amount: {
      elementType: "input",
      elementConfig: {
        type: "number",
        name: "amount",
        id: "amount",
        placeholder: amountLabel,
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const contactForm = () => {
  return {
    topic: {
      elementType: "select",
      elementConfig: {
        options: [
          { value: "", displayValue: `Seleziona argomento`, disabled: true },
          { value: "Premio", displayValue: "Premio" },
          { value: "Gioco", displayValue: "Gioco" },
          { value: "Altro", displayValue: "Altro" },
        ],
        placeholder: `Seleziona argomento`,
        name: "topic",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
    message: {
      elementType: "textarea",
      elementConfig: {
        name: "message",
        id: "message",
        placeholder: "Il tuo messaggio",
      },
      label: "",
      value: "",
      validation: {
        required: true,
        touched: false,
      },
      valid: false,
    },
  };
};

export const profileform = (firstname, lastname, email, dob) => {
  return {
    firstname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstname",
        id: "firstname",
        placeholder: "Nome",
      },
      label: "",
      value: firstname,
      validation: {
        required: true,
        touched: false,
      },
      valid: firstname ? true : false,
    },
    lastname: {
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastname",
        id: "lastname",
        placeholder: `Cognome`,
      },
      label: "",
      value: lastname,
      validation: {
        required: true,
        touched: false,
      },
      valid: lastname ? true : false,
    },
    email: {
      elementType: "input",
      elementConfig: {
        type: "email",
        name: "email",
        id: "email",
        placeholder: "Email",
      },
      label: "",
      value: email,
      validation: {
        required: true,
        touched: false,
      },
      valid: email ? true : false,
    },
    dob: {
      elementType: "datepicker",
      elementConfig: {
        placeholder: "Data di nascita",
        name: "dob",
      },
      label: "",
      value: dob,
      valuetosend: dob,
      validation: {
        required: true,
        touched: false,
        maxDate: "today",
      },
      valid: dob ? true : false,
    },
  };
};
