import styled from 'styled-components';

const Wrapper = styled.div`
  max-width: ${props => props.small ? '50rem' : '75rem'};
  margin: auto;
  padding: 0 0.9375rem; 
  box-sizing: border-box;
  position: relative;
`;

export default Wrapper;