import React from "react";
import { respondTo } from "../../theme/mixin";
import { Hamburger, SwitchLang } from "../Ui";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import LoginBtn from "../LoginBtn";
import Logout from "../Logout";
import styled from "styled-components";

const MenuLateralContainer = styled.div`
  display: flex;
  align-items: center;

  ${respondTo.lg`
      margin-left: auto;
    `}
`;

const MenuContainerDesk = styled.div`
  display: none;
  ${respondTo.lg`
        display: flex;     
    `}
`;

const MenuContainerMobile = styled.div`
  display: flex;
  ${respondTo.lg`
        display: none;
    `}
`;

const MenuLateral = (props) => {
  const {
    toggleSidebar,
    logout,
    isLogged,
    ownLogin,
    oauths,
    oauthInfo,
    bootApp,
    langs,
    oauth_profile_url,
  } = props;

  const [t] = useTranslation();

  return (
    <MenuLateralContainer>
      <MenuContainerDesk>
        {isLogged ? (
          <Logout clicked={logout} label={t("auth.logoutlabel")} />
        ) : (
          <LoginBtn
            label={t("auth.signinlabel")}
            ownlogin={ownLogin}
            oauths={oauths}
            oauthinfo={oauthInfo}
          />
        )}
        {langs.length > 1 ? (
          <SwitchLang bootApp={bootApp} langs={langs} />
        ) : null}
      </MenuContainerDesk>
      <MenuContainerMobile>
        <Hamburger clicked={toggleSidebar} />
      </MenuContainerMobile>
    </MenuLateralContainer>
  );
};

MenuLateral.propTypes = {
  toggleSidebar: PropTypes.func,
  logout: PropTypes.func,
  isLogged: PropTypes.string,
  ownLogin: PropTypes.number,
  oauths: PropTypes.array,
  oauthInfo: PropTypes.object,
  appInit: PropTypes.func,
  langs: PropTypes.array,
  oauth_profile_url: PropTypes.string,
};

export default MenuLateral;
