import React, { useState, useEffect } from "react";
import useForm from "../../../hooks/useForm";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Input, Button, Wrapper, Loader, Modal } from "../../Ui";
import Fade from "../../Fade";
import styled from "styled-components";
import { respondTo } from "../../../theme/mixin";
import { useTranslation } from "react-i18next";
import customaxios from "../../../config/axios-refresh-token";
import { getSlug } from "../../../utils/utils";
import ReactGA from "react-ga";

const FormContainer = styled.div`
  padding: 20px;

  ${respondTo.sm`
		padding: 0;
		margin: 0 -15px;
		margin-top: 50px;
	`}
`;

const FormList = styled.ul`
  list-style-type: none;
  background-color: #fff;
  border-radius: 20px;

  ${respondTo.sm`
    display: flex;
    flex-wrap: wrap;
		align-items: flex-start;
		border-radius: 0;
    background-color: transparent;
    padding: 0 50px;
  `}

  ${respondTo.md`
    padding: 0;
    flex-wrap: nowrap;
	`}

  & li {
    padding: 20px;
    border-bottom: 1px solid #c5cbd1;

    ${respondTo.sm`
			width: 100%;
			margin: 0 15px;
			overflow: hidden;
			background-color: #fff;
			border-radius: 20px;
			border: none;
      box-shadow: 0 3px 6px #63666919;
      margin-bottom: 30px;
    `}

    ${respondTo.md`
			width: calc(33.333% - 15px);
		`}

    &:first-child {
      padding-bottom: 0;
      border-bottom: none;
    }

    .staticInputs {
      padding: 20px 20px 2px 20px;
      margin-top: 20px;
      margin-left: -20px;
      margin-right: -20px;
      background-color: #f0f0f0;

      .staticInputHeader {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        span {
          text-transform: uppercase;
          font-size: 12px;

          &:first-child {
            color: ${(props) => props.theme.primary};
          }
        }
      }

      input {
        background-color: #e2e2e2;
      }
    }

    & input[type="number"] {
      background-color: ${(props) => props.theme.input.bg};
    }
  }

  & li:first-child > div {
    margin-bottom: 0;
  }

  & li:last-child {
    border-bottom: none;
  }
`;

const TextP = styled.h3`
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 20px;
`;

const Response = styled.div`
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
  width: 100%;

  p {
    display: block;
    margin-bottom: 20px;
    color: ${(props) => props.theme.secondary};
  }
`;

const Title = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 10px;
`;
const Content = styled.p`
  font-size: 14px;
`;

const Extra = styled.p`
  font-size: 20px;
  margin-bottom: 15px;
`;

const Form = (props) => {
  const {
    form: {
      data,
      type,
      i18l: { title, content, extra },
    },
    lang,
    id,
    playslog,
    formResponse,
    played,
  } = props;

  let showResponse = false;
  if (formResponse !== null && played) {
    showResponse = true;
  }

  const [loading, setLoading] = useState(false);
  const [showFormResponse, setShowFormResponse] = useState(showResponse);
  const [showModal, setShowModal] = useState(false);
  const [info, setInfo] = useState("");

  const [blockFields, setBlockFields] = useState(true);
  const [immagine, setImmagine] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [resetImage, setResetImage] = useState(false);

  const [t] = useTranslation();
  const dataForm = JSON.parse(data);

  let slug = getSlug();

  const submitHandler = (e) => {
    const objData = {};
    for (const property in formData) {
      objData[property] = formData[property].value;
      if (formData[property].valuetosend) {
        objData[property] = formData[property].valuetosend;
      } else {
        objData[property] = formData[property].value;
      }
    }

    const data = new FormData();

    let fileArray = [];
    let fileExtraArray = [];

    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].name === "file") {
        fileArray.push(selectedFiles[i]);
      } else if (selectedFiles[i].name === "fileextra") {
        fileExtraArray.push(selectedFiles[i]);
      }
    }

    fileArray.forEach(function (element) {
      if (fileArray[fileArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });
    fileExtraArray.forEach(function (element) {
      if (fileExtraArray[fileExtraArray.length - 1] === element) {
        data.append(element.name, element.file);
      }
    });

    data.append("data", JSON.stringify(objData));
    data.append("section", "form");
    if (type === "post") {
      data.append("playslog", playslog);
    }
    setLoading(true);
    customaxios
      .post(`game/${slug}/${id}/data`, data)
      .then((response) => {
        setLoading(false);
        if (response.status === 200) {
          if (response.data.data.status) {
            setShowFormResponse(true);
            ReactGA.event({
              category: "Convalida",
              action: "Convalida",
            });
          } else {
            setShowModal(true);
            setInfo(response.data.data.info);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        setShowModal(true);
        setInfo("errore interno: segnala questo errore al servizio clienti" + error);
      });
  };

  const closeModal = () => {
    setInfo("");
    setShowModal(false);
  };

  let datePickerNames = [];

  for (var prop in dataForm) {
    if (dataForm[prop].elementType === "datepicker") {
      datePickerNames.push(dataForm[prop].elementConfig.name);
    }
  }

  const onSetImmagine = (value) => {
    setImmagine(value);
    if (!value) {
      setResetImage(true);
    }
  };
  const onSetSelectedFiles = (file) => {
    setSelectedFiles((prevState) => {
      return [...prevState, file];
    });
  };

  useEffect(() => {
    if (immagine) {
      setBlockFields(false);
    } else {
      setBlockFields(true);
    }
  }, [immagine]);

  const {
    inputChangedHandler,
    formData,
    formSubmitHandler,
    firstSubmit,
    showError,
    startDate,
    datePickerHandler,
    fileUploadHandler,
    deleteFileHandler,
    selectedFile,
    imgPreviewUrl,
    districts,
    cities,
    zipcode,
    onSetFormValid,
    resetFormHandler,
  } = useForm(submitHandler, dataForm, datePickerNames);

  let inputArr = [];
  for (let key in formData) {
    inputArr.push({ name: key, ...formData[key] });
  }

  let inputs = inputArr.map((inp) => {
    return (
      <Input
        error={inp.errorMessage}
        key={inp.name}
        value={inp.value}
        elementType={inp.elementType}
        elementConfig={inp.elementConfig}
        changed={inputChangedHandler}
        label={inp.label}
        isvalid={inp.valid}
        istouched={inp.validation.touched}
        showError={showError}
        firstSubmit={firstSubmit}
        startdate={startDate}
        changedDatePicker={datePickerHandler}
        fileUploadHandler={fileUploadHandler}
        deleteFileHandler={deleteFileHandler}
        imagePreviewUrl={imgPreviewUrl}
        lang={lang}
        inModal={true}
        radioInputs={inp.inputs}
        districts={districts}
        cities={cities}
        zipcode={zipcode}
        onSetImmagine={onSetImmagine}
        onSetFormValid={onSetFormValid}
        onSetSelectedFiles={onSetSelectedFiles}
        resetFormHandler={resetFormHandler}
        resetImage={resetImage}
        {...inp}
      />
    );
  });

  const user = useSelector((state) => state.user.user.userinfo);

  const staticData = [
    {
      label: "",
      value: user.firstname || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "firstname",
        id: "firstname",
        placeholder: "Nome",
        disabled: true,
      },
    },
    {
      label: "",
      value: user.lastname || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "lastname",
        id: "lastname",
        placeholder: "Cognome",
        disabled: true,
      },
    },
    {
      label: "",
      value: user.email || "",
      valid: 1,
      validation: { required: 0, touched: 0 },
      elementType: "input",
      elementConfig: {
        type: "text",
        name: "email",
        id: "email",
        placeholder: "Email",
        disabled: true,
      },
    },
  ];

  return (
    <div className="container">
      <FormContainer>
        {!showFormResponse ? (
          <Fade show={true}>
            <form onSubmit={formSubmitHandler}>
              <FormList>
                <li>
                  {title && <TextP>{title}</TextP>}
                  {content && <Content>{content}</Content>}
                  <div className="staticInputs">
                    <div className="staticInputHeader">
                      <span>Io sottoscritto,</span>
                      <span>Dati non modificabili</span>
                    </div>
                    {staticData.map((inp, indx) => (
                      <Input
                        error={inp.errorMessage}
                        key={indx}
                        value={inp.value}
                        elementType={inp.elementType}
                        elementConfig={inp.elementConfig}
                        changed={inputChangedHandler}
                        label={inp.label[lang]}
                        isvalid={inp.valid}
                        istouched={inp.validation.touched}
                        firstSubmit={firstSubmit}
                        startdate={startDate}
                        changedDatePicker={datePickerHandler}
                        inModal={true}
                        radioInputs={inp.inputs}
                      />
                    ))}
                  </div>
                </li>
                <li>
                  <TextP>Documento d'identità</TextP>
                  {inputs.slice(0, 7)}
                </li>
                <li>
                  {inputs[6].props.value === "1" ? (
                    <>
                      <TextP>Indirizzo di spedizione</TextP>
                      {inputs.slice(7)}
                    </>
                  ) : null}
                  <Button upper fullwidth type="submit" disabled={loading}>
                    {loading ? <Loader inBtn show={true} /> : t("auth.send")}
                  </Button>
                </li>
              </FormList>
            </form>
          </Fade>
        ) : (
          <Response>
            <p>
              Grazie per aver giocato
            </p>
            <Link to="/">
              <Button upper>Torna alla homepage</Button>
            </Link>
          </Response>
        )}
        {showModal ? (
          <Modal show={showModal} close={closeModal}>
            <p>{info && info}</p>
          </Modal>
        ) : null}
      </FormContainer>
    </div>
  );
};

export default Form;
